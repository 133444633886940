import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { modalOnState, selectedStudentState, unmatchedPhotosState, studentsState } from '../../stores/studioAtoms';
import { useRecoilState } from 'recoil';
import TextButton from '../Button/TextButton';
import { useState } from 'react';
import { matchPhoto, deleteUploadedPhoto } from '../../services/apis/StudioApi';
import StudentSelector from '../Selector/StudentSelector';
import { useSnackbar } from '../Layout/SnackbarProvider';
import { getTimeOnly } from '../../utils/datetime_formats';

const MatchPhotoModal = ({ closeAction, setNeedUpdate }) => {
    const [modalOn, setModalOn] = useRecoilState(modalOnState)
    const [matchingPhoto, setMatchingPhoto] = useState(null);
    const [matchingPhotoIndex, setMatchingPhotoIndex] = useState(0)
    const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentState);
    const [unmatchedPhotos, setUnmatchedPhotos] = useRecoilState(unmatchedPhotosState)
    const [students, setStudents] = useRecoilState(studentsState)
    const [onProgress, setOnProgress] = useState(false)
    const { showSnackbar } = useSnackbar();
    const [onLoading, setOnLoading] = useState(false)


    function closeModal() {
        setModalOn(false)
        closeAction()
    }

    const handleMatchingPhoto = () => {
        if (!unmatchedPhotos) return;
        setMatchingPhoto(unmatchedPhotos[matchingPhotoIndex])
        console.log(matchingPhoto)
    }

    const loadPrevPhoto = () => {
        setMatchingPhotoIndex(matchingPhotoIndex - 1)
    }

    const loadNextPhoto = () => {
        setMatchingPhotoIndex(matchingPhotoIndex + 1)
    }

    useEffect(() => {
        setMatchingPhoto(unmatchedPhotos[matchingPhotoIndex])
    }, [matchingPhotoIndex])


    useEffect(() => {
        handleMatchingPhoto();
    }, [unmatchedPhotos])


    return (
        <Transition appear show={modalOn} as={Fragment}>
            <Dialog as="div" className="relative z-10 justify-center overflow-visible" onClose={closeModal}>
                <Dialog.Overlay className="fixed inset-0 bg-gray opacity-50" />
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-visible">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >

                            <div className="fixed inset-0 flex items-center justify-center p-4 overflow-y-visible">
                                <Dialog.Panel
                                    className="bg-white rounded max-w-md mx-auto transform overflow-visible text-center align-middle shadow-xl transition-all border-2 border-black">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-blue-900 mt-3 flex px-4"
                                    >
                                        <TextButton
                                            label="이전"
                                            enabled={matchingPhotoIndex > 0}
                                            handleClick={loadPrevPhoto}
                                        />
                                        <p className='flex-grow'>사진 분류</p>
                                        <TextButton
                                            label="다음"
                                            enabled={matchingPhotoIndex < unmatchedPhotos.length - 2}
                                            handleClick={loadNextPhoto}
                                        />
                                    </Dialog.Title>
                                    <div className='flex p-4'>
                                        {
                                            matchingPhoto ?
                                                onLoading ?
                                                    "로딩중"
                                                    : <img
                                                        src={matchingPhoto.original}
                                                        alt="원본 사진"
                                                        key={matchingPhoto.id}
                                                        onLoad={() => setOnLoading(false)} />
                                                : "다음 사진 불러오는중"
                                        }

                                    </div>
                                    <div>
                                        촬영시각 {matchingPhoto ? getTimeOnly(matchingPhoto.taken_at) : "정보없음"}
                                    </div>
                                    <div className='flex flex-row items-center justify-center gap-x-3'>
                                        <StudentSelector students={students} handleStudentSelect={(value) => {
                                            setSelectedStudent(value)
                                        }} />
                                        <TextButton label="저장"
                                            loading={onProgress}
                                            handleClick={() => {
                                                setOnProgress(true)
                                                var data = {
                                                    "photo_id": matchingPhoto.id,
                                                    "student_id": selectedStudent.id,
                                                }
                                                matchPhoto(data).then(res => {
                                                    if (res.status === 200) {
                                                        showSnackbar("분류 완료. 다음 사진 로딩중")
                                                        var newUnmatchedPhotos = [
                                                            ...unmatchedPhotos.slice(0, matchingPhotoIndex),
                                                            ...unmatchedPhotos.slice(matchingPhotoIndex + 1)
                                                        ]
                                                        setNeedUpdate(true)
                                                        setUnmatchedPhotos(newUnmatchedPhotos)
                                                    } else {
                                                        showSnackbar("오류 발생")
                                                    }
                                                    setOnProgress(false)
                                                })
                                            }} />
                                        <TextButton
                                            label="삭제"
                                            bgColor='bg-red-500'
                                            loading={onProgress}
                                            handleClick={() => {
                                                setOnProgress(true)
                                                deleteUploadedPhoto(matchingPhoto.id).then(res => {
                                                    if (res.status == 204) {
                                                        showSnackbar("삭제 완료. 다음 사진 로딩중")
                                                        var newUnmatchedPhotos = [
                                                            ...unmatchedPhotos.slice(0, matchingPhotoIndex),
                                                            ...unmatchedPhotos.slice(matchingPhotoIndex + 1)
                                                        ]
                                                        setUnmatchedPhotos(newUnmatchedPhotos)
                                                        setOnProgress(false)
                                                    }
                                                })
                                            }} />
                                    </div>
                                    <div className="mt-4 flex justify-center">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center border border-gray bg-white border-gray-400 px-9 py-1 mb-3 text-sm text-gray-500 font-medium text-blue-900 hover:bg-midnight-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            닫기
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default MatchPhotoModal;