import SelectedPhotoList from "./components/SelectedPhotoList";
import Button from "../../../components/Button/Button";
import { requestDownload } from "../../../services/apis/common";
import { getAllSchoolFileCondition, createAllSchoolFile } from "../../../services/apis/StudioApi";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from "../../../components/Layout/SnackbarProvider";
import { wsHost } from "../../../services/apis/common";


const SelectedPhotosPage = () => {
    const { schoolId } = useParams();
    const [isDownloading, setIsDownloading] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [downloadAvailable, setDownloadAvailable] = useState(false)
    const { showSnackbar } = useSnackbar();
    const [socket, setSocket] = useState(null)
    const [message, setMessage] = useState("")

    const handleDownload = () => {
        setIsDownloading(true)
        requestDownload({ schoolId: schoolId }).then(res => {
            setIsDownloading(false)
        }).catch(err => {
            console.log(err)
            showSnackbar("다운로드 실패")
        })
        return;
    }

    const handleCreate = async () => {
        setIsDownloading(true)
        setIsCreating(true)
        setMessage("")
        var res = await createAllSchoolFile({ schoolId: schoolId })
        if (res.status === 200) {
            console.log(res.data)
            setIsDownloading(false)
            return;
        } else {
            setIsDownloading(false)
            console.log(res.status)
            console.log(res.data)
            return
        }
    }

    const getBtnLabel = () => {
        if (isDownloading) {
            return "다운로드 요청중"
        }
        return "학생 전체 사진 다운로드"
    }

    useEffect(() => {
        getAllSchoolFileCondition(schoolId).then(
            res => {
                if (res.status === 200) {
                    console.log(res.data)
                    setDownloadAvailable(true)
                    return;
                } else if (res.status === 204) {
                    setDownloadAvailable(false)
                    return
                } else {
                    console.log(res.status)
                    console.log(res.data)
                    return
                }
            }
        )
    }, [])

    useEffect(() => {
        const SOCKET_SERVER_URL = `${wsHost}ws/upload/${schoolId}/`
        const newSocket = new WebSocket(SOCKET_SERVER_URL);

        newSocket.onopen = () => {
            console.log("WebSocket Connected");
        };

        newSocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log(message);
            if (message.message.startsWith("201-")) {
                const url = message.message.substring(4);
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                // downloadLink.download = "filename.zip";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                setMessage("생성 완료. 다운로드 시작")
                setIsCreating(false)
                setDownloadAvailable(true)
            } else {
                setDownloadAvailable(false)
                setMessage(message.message)
            }
        };

        setSocket(newSocket);

        return () => {
            if (newSocket.readyState === WebSocket.OPEN) {
                newSocket.close();
            }
        }
    }, [setSocket])

    return (
        <>
            <div className="flex flex-col w-screen">
                <div className="my-4 mx-16">
                    <Button onClick={handleCreate} disabled={isDownloading || isCreating}>학생 전체 파일 생성</Button>
                </div>
                {message ? <div className="flex justify-center">
                    {message}
                </div> : null}
                <div className="mx-16">
                    <Button onClick={handleDownload} disabled={isDownloading || !downloadAvailable} disabled_label={isDownloading ? "처리중" : "파일 없음"}>{getBtnLabel()}</Button>
                </div>
                <div className="p-2">
                    <SelectedPhotoList />
                </div>
            </div>
        </>
    )
}

export default SelectedPhotosPage;