import { useEffect, useState } from 'react'
import {
    CubeIcon,
    AcademicCapIcon,
} from '@heroicons/react/24/outline'
import SchoolInfoPage from './SchoolInfoPage'
import SchoolAdminTab from '../../school/schoolAdmin/SchoolAdminPage'

const secondaryNavigation = [
    { name: '관리', href: '#', icon: AcademicCapIcon, current: true },
    { name: '정보', href: '#', icon: CubeIcon, current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SchoolAdminPage() {
    const [currentTab, setCurrentTab] = useState('관리')
    const [sideMenu, setSideMenu] = useState(secondaryNavigation)

    const buildTabContent = () => {
        switch (currentTab) {
            case '관리':
                return <SchoolAdminTab />
            case '정보':
                return <SchoolInfoPage />
        }
    }

    useEffect(() => {
        setSideMenu(
            secondaryNavigation.map((item) => {
                item.current = item.name === currentTab
                return item
            })
        )
    }, [currentTab])

    return (
        <>
            <div className="max-w-7xl">
                <h1 className="sr-only">학교 관리</h1>

                {/* <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4">
                    <nav className="flex-none px-4 sm:px-6">
                        <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap">
                            {secondaryNavigation.map((item) => (
                                <li key={item.name}>
                                    <div
                                        onClick={() => { setCurrentTab(item.name) }}
                                        className={classNames(
                                            item.current
                                                ? 'bg-gray-50 text-indigo-600'
                                                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 hover:cursor-pointer',
                                            'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                'h-6 w-6 shrink-0'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </aside> */}

                <div className="px-4 py-16 sm:px-6 h-96 w-full">
                    <SchoolAdminTab />
                </div>
            </div>
        </>
    )
}
