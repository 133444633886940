import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { SnackbarProvider } from "./SnackbarProvider";


const Layout = (props) => {
    return (
        <div>
            <Header />
            <main>
                <SnackbarProvider>
                    {props.children}
                </SnackbarProvider>
            </main>
            <Footer />
        </div>
    )
}

export default Layout;