export const timeOnlyOptions = {
    timeZone: 'Asia/Seoul', // 또는 'Asia/Tokyo' 등 +9 시간대에 해당하는 타임존 사용
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // 24시간 형식 사용
};

export const getTimeOnly = (utcDateStr) => {
    const utcDate = new Date(utcDateStr);
    try {
        return new Intl.DateTimeFormat('ko-KR', timeOnlyOptions).format(utcDate);
    } catch {
        console.log(`utcDateStr:: ${utcDateStr}`)
        return utcDate
    }
}