import { useState, useEffect } from 'react';

const SchoolEventList = ({ events }) => {
    // console.log(events);

    if (!events || events.length === 0) return <p className='text-sm mt-3'>행사가 없습니다.</p>

    return (
        <div className='mt-3'>
            {events.map(event => (
                <div key={event.id}>
                    <p>- {event.name}</p>
                </div>
            ))}
        </div>
    )
}

export default SchoolEventList;