import React from 'react';
import { schoolNameSelector, classroomSelector } from '../../stores/schoolSelectors';
import { studioSelector } from '../../stores/studioSelectors'
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import './Header.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper'


const Header = () => {
    const schoolName = useRecoilValue(schoolNameSelector)
    const classroom = useRecoilValue(classroomSelector)
    const studio = useRecoilValue(studioSelector)
    const { schoolId } = useParams()
    const getCurrentUser = () => {
        if (classroom) return `${classroom.name}반`
        if (studio) return `${studio}`
        try {
            var studioName = JSON.parse(localStorage.getItem("studioName"))
        } catch {
            // TODO: remove this code block after May 15th
            var studioName = localStorage.getItem("studioName")
        }
        return studioName ?? ""
    }
    const navigate = useNavigate()
    const goHome = () => {
        navigate("/")
    }

    const goMenu = () => {
        navigate(`/school/${schoolId}`)
    }

    // Popover properties
    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-end', // 패널을 참조 요소의 아래쪽 끝에 위치시키도록 설정
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true, // 메인 축과 대체 축 둘 다에서 오버플로우 방지
                    boundary: 'clippingParents', // 부모 요소를 경계로 설정
                    tether: true, // 패널이 참조 요소를 벗어나지 않도록 고정
                    rootBoundary: 'viewport', // 뷰포트를 최종 경계로 설정
                },
            },
            {
                name: 'flip', // 주어진 방향이 차단되면 패널을 반대 방향으로 뒤집음
                options: {
                    padding: 3, // 뷰포트 경계와 패널 사이의 최소 거리를 설정
                },
            },
        ],
    });


    return (
        <header className="header fixed w-full bg-blue-50 h-16 z-20">
            <div className="header-title cursor-pointer" onClick={goHome}>
                <img
                    className="h-10"
                    src="/images/eALBUM-logo-portrait.png"
                    alt="eAlbum"
                />
            </div>
            <div className="ml-4 cursor-pointer" onClick={goMenu}>{schoolName}</div>
            {
                studio ?
                    <Popover className="relative">
                        <Popover.Button
                            className="header-user"
                            ref={setReferenceElement}>{getCurrentUser()}</Popover.Button>

                        <Popover.Panel
                            className="absolute z-10 w-40 mt-3 bg-white text-black rounded-lg shadow-lg ring-1 ring-black/5 text-center"
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}>
                            <div
                                className='cursor-pointer hover:bg-gray-300'
                                onClick={() => {
                                    localStorage.removeItem("user")
                                    navigate("/studio/login")
                                    window.location.reload()
                                }}>
                                Logout
                            </div>
                            <div className="border-t border-gray-300"></div>
                            <div
                                className='cursor-pointer hover:bg-gray-300'
                                onClick={() => {
                                    navigate("/studio/temp-password")
                                }}
                            >
                                <Popover.Button href="/" className="hover:bg-gray-300">
                                    임시비밀번호 관리
                                </Popover.Button>
                            </div>
                            <div className="border-t border-gray-300"></div>
                            <div
                                className='cursor-pointer hover:bg-gray-300'
                                onClick={() => {
                                    navigate("/studio/new-password")
                                }}>
                                <Popover.Button href="/" className="hover:bg-gray-300">
                                    비밀번호 관리
                                </Popover.Button>
                            </div>
                        </Popover.Panel>
                    </Popover>
                    : <div className="header-user">{getCurrentUser()}</div>

            }

        </header>
    );
};

export default Header;
