import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button/Button'
import FileUploader from "../../../components/FileUploader/FileUploader";
import { uploadStudentList, addStudent, getStudentListForStudio } from "../../../services/apis/SchoolApi";
import List from "../../../components/List/StudentList";
import { useRecoilValue } from "recoil";
import { studioState } from "../../../stores/studioAtoms";
import { useSnackbar } from "../../../components/Layout/SnackbarProvider";


const downloadSample = () => {
    const fileUrl = `${process.env.PUBLIC_URL}/assets/(샘플)학급명렬표.xlsx`;

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "(샘플)학급명렬표.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
}


const StudentListPage = () => {
    const [file, setFile] = useState(null);
    const { schoolId, classId } = useParams();
    const [students, setStudents] = useState([]);
    const [addUI, setAddUI] = useState(false);
    const [newStudent, setNewStudent] = useState({
        "number": "",
        "name": "",
        "email": "",
    })
    const [errorMessage, setErrorMessage] = useState("")
    const studio = useRecoilValue(studioState)
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        getStudentListForStudio(schoolId, classId).then(res => {
            let newStudents = []
            res.data.forEach(student => {
                newStudents.push({
                    id: student.id,
                    name: student.name,
                    email: student.email,
                    number: student.number,
                })
            })
            setStudents(newStudents)
        });
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setErrorMessage("")
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('student_list', file);

        uploadStudentList(schoolId, classId, studio, formData)
            .then(res => {
                if (res.status === 201) {
                    setStudents(res.data)
                    showSnackbar("학생 목록이 생성되었습니다.")
                } else {
                    console.log("failed to upload")
                    console.log(res.data.message)
                    setErrorMessage(res.data.message)
                    setFile(null)
                }
            })

    };

    const handleAddStudentClick = () => {
        console.log(newStudent)
        if (newStudent.number === "") {
            setErrorMessage("번호를 입력해주세요")
            return
        }
        addStudent(schoolId, classId, newStudent).then(res => {
            if (res.status === 201) {
                setErrorMessage("")
                setAddUI(false)
                setStudents([...students, res.data])
                setNewStudent({
                    "number": "",
                    "name": "",
                    "email": "",
                })
            } else {
                var message = res.data.message
                if (message.includes("이메일")) {
                    setNewStudent({ ...newStudent, email: "" })
                } else if (message.includes("번호")) {
                    setNewStudent({ ...newStudent, number: "" })
                }
                setErrorMessage(message)
            }
        })
    }

    const handleNewStudentChange = (event) => {
        setNewStudent({ ...newStudent, [event.target.name]: event.target.value });
    }



    return (
        <>
            <h1 className="text-lg font-bold mb-4">학급 명렬표</h1>
            {students.length > 1 ? (
                <div>
                    <List students={students} />
                    {
                        !addUI ? (
                            <div className="w-36 mx-auto">
                                <Button onClick={() => { setAddUI(true) }}>+ 학생 추가</Button>
                            </div>
                        ) : (
                            <div>
                                <div className="flex gap-x-3">
                                    <input className="border w-10" placeholder="번호" name="number" onChange={handleNewStudentChange} value={newStudent.number} />
                                    <input className="border w-20" placeholder="이름" name="name" onChange={handleNewStudentChange} />
                                    <input className="border w-48" placeholder="이메일" name="email" onChange={handleNewStudentChange} />
                                    <span className="text-blue-400 cursor-pointer hover:bg-blue-200 hover:text-white px-2" onClick={handleAddStudentClick}>추가</span>
                                </div>
                                <p className="text-red-400 mx-auto italic">{errorMessage}</p>
                            </div>
                        )
                    }


                </div>
            ) :
                <div>
                    <Button onClick={downloadSample}>
                        샘플 파일 다운로드
                    </Button>
                    <p className="text-red-400 mx-auto italic text-center">{errorMessage}</p>
                    <form onSubmit={handleSubmit}>
                        {
                            file ? (
                                <div className="h-64 flex flex-col items-center">
                                    <div className="flex-1 flex items-center">
                                        <p className="bg-blue-300 px-8 py-2 rounded-lg text-gray-800">업로드된 파일</p>
                                    </div>
                                    <div className="flex-1">
                                        {file.name}
                                    </div>
                                    <Button type="submit">
                                        학생 데이터 생성하기
                                    </Button>
                                </div>
                            ) : (
                                <FileUploader onChange={handleFileChange} setFile={setFile} />
                            )
                        }
                        <br />
                        <div className={file ? "visible" : "invisible"}>

                        </div>
                    </form>

                </div>
            }
        </>
    )
}

export default StudentListPage;