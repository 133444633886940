import { useRecoilValue } from "recoil"
import { studioState } from "../../../stores/studioAtoms"
import { useEffect, useState } from "react"
import DatePicker from "react-datepicker";
import { getTempPassword, createTempPassword } from "../../../services/apis/StudioApi"
import { useSnackbar } from "../../../components/Layout/SnackbarProvider";

const TempPasswordPage = () => {
    const studio = useRecoilValue(studioState)
    const [tempPassword, setTempPassword] = useState({
        password: "",
        available_on: "",
    })
    const [availableOn, setAvailableOn] = useState(new Date())
    const requestCreate = () => {
        createTempPassword(availableOn).then(res => {
            if (res.status == 200) {
                setTempPassword(res.data)
                setAvailableOn(Date.parse(res.data["available_on"]))
            }
        })
    }
    const { showSnackbar } = useSnackbar()
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            showSnackbar('복사되었습니다');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }

    };

    useEffect(() => {
        getTempPassword().then(res => {
            if (res.status == 200) {
                setTempPassword(res.data)
                setAvailableOn(Date.parse(res.data["available_on"]))
            } else if (res.status == 204) {
                console.log("no password")
            }
        })
    }, [])

    return (
        <div>
            <h1 className="text-2xl font-semibold leading-7 text-gray-900 mb-4">임시비밀번호 관리</h1>
            {tempPassword.password != "" ?
                <div>
                    <div>
                        임시비밀번호
                    </div>
                    <div>
                        {tempPassword.password}
                        <i
                            className="ml-2 mr-1 fa-regular fa-copy opacity-40 hover:opacity-80 hover:cursor-pointer"
                            onClick={() => {
                                copyToClipboard(tempPassword.password)
                            }}
                        ></i>
                    </div>
                </div> : null
            }
            <div className="col-span-1">
                <label className="block text-sm font-bold leading-6 text-gray-900 pt-1.5">
                    사용일
                </label>
            </div>
            <div className="col-span-2">
                <div className="">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <DatePicker
                            showIcon
                            onChange={setAvailableOn}
                            selected={availableOn}
                            dateFormat="yyyy.MM.dd"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
            </div>
            <button
                type="button"
                className="rounded-md bg-midnight-600 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-midnight-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midnight-600"
                onClick={requestCreate}
            >
                저장
            </button>
        </div>
    )
}

export default TempPasswordPage