import { useEffect, useState } from "react";
import { getSchools } from "../../services/apis/StudioApi";
import SchoolSelector from "../../components/Selector/SchoolSelector";
import { schoolNameState } from "../../stores/schoolAtoms"
import { useSetRecoilState } from "recoil"
import { useNavigate } from 'react-router-dom'
import Title from "../../components/Title/Title";

const SelectSchoolPage = () => {
    const [schools, setSchools] = useState([]);
    const setSchoolName = useSetRecoilState(schoolNameState)
    const navigate = useNavigate()
    const [selected, setSelected] = useState(null)
    const onSchoolSelect = (value) => {
        setSelected(value)
        setSchoolName(value.name)
        navigate(`/school/${value.id}/`)
    }

    useEffect(() => {
        getSchools().then(res => {
            if (res.status === 200) {
                setSchools(res.data)
            } else if (res.status === 401) {
                navigate("/studio/login")
            }
        })
        setSchoolName("")
    }, [])

    return (
        <div className="">
            <Title label="학교 선택" />
            <SchoolSelector schools={schools} onSchoolSelect={onSchoolSelect} />
        </div>
    )
}

export default SelectSchoolPage;