const Footer = () => {
    return (
        <div className="h-16">
            <div className="flex justify-center text-midnight flex-row gap-x-1">
                <p>eALBUM</p>
                <p className="text-midnight-400 text-xs items-end">v0.17.0</p>
            </div>
            <div className="flex justify-center text-sm mt-1 flex-col md:flex-row text-center">
                <span className="md:mr-5 flex-1 md:text-center">(주)미디어피앤피</span>
                <div className="text-gray flex-auto flex flex-col sm:flex-row justify-center text-center">
                    <span className="sm:ml-2 flex-1">
                        서울 종로구 대학로 57, 13층
                    </span>
                    <span className="text-gray flex-1">대표자 김용우 </span>
                    <span className="sm:ml-2 flex-1">사업자등록번호 113-88-00809</span>

                </div>
            </div>
            <div className="text-gray-light flex justify-center my-6 text-sm">
                <span><a href="https://ssamko.notion.site/0d2679db94f94c41a504515f784f4db4?pvs=4">서비스 이용약관</a></span>
                <span className="ml-5"><a href="https://ssamko.notion.site/26b2aa45b30742a4b85b476891ec8eff?pvs=4">개인정보처리방침</a></span>
            </div>
        </div>
    )
}

export default Footer