import SceneList from "../../components/Selector/SceneSelector"
import Button from "../../components/Button/Button"
import { useNavigate } from "react-router-dom"
import Title from "../../components/Title/Title"
import { useSetRecoilState } from "recoil";
import { classroomState } from "../../stores/schoolAtoms";
import { sceneState } from "../../stores/studioAtoms";
import { useEffect } from "react";

const SelectScenePage = () => {
    const setClassroom = useSetRecoilState(classroomState)
    const setScene = useSetRecoilState(sceneState)
    const navigate = useNavigate()
    const goCreatePage = () => {
        navigate("new")
    }
    useEffect(() => {
        setClassroom(null)
        setScene(null)
        localStorage.setItem("currentScene", null)
    }, [])


    return (
        <>
            <div className="w-96">
                <div className="flex-row flex justify-end mb-4">
                    <div className="w-20">
                        <Button onClick={goCreatePage} color="blue" small={true}>+ 추가</Button>
                    </div>
                </div>
                <Title label="촬영" />
                <SceneList isUsedOn={"shot"} fit={true} />
            </div>
        </>
    )
}

export default SelectScenePage