import { atom } from "recoil";


export const currentStudentState = atom({
    key: "currentStudentState",
    default: null,
})

export const currentClassStudentsState = atom({
    key: "currentClassStudentsState",
    default: [],
})

export const modalOnState = atom({
    key: "modalOnState",
    default: false
})

export const sceneState = atom({
    key: "sceneState",
    default: null,
})

export const shotState = atom({
    key: "shotState",
    default: null,
})

export const imagesState = atom({
    key: "imagesState",
    default: []
})

export const studioState = atom({
    key: "studioState",
    default: null,
})

export const selectedStudentState = atom({
    key: "selectedStudentState",
    default: null,
})

export const unmatchedPhotosState = atom({
    key: "unmatchedPhotosState",
    default: []
})

export const selectedPhotoState = atom({
    key: "selectedPhotoState",
    default: null,
})

export const studentsState = atom({
    key: "studentsState",
    default: []
})

export const selectedPhotoIndexState = atom({
    key: "selectedPhotoIndexState",
    default: 0
})

export const studentPhotosState = atom({
    key: "studentPhotosState",
    default: []
})

export const allStudentPhotosState = atom({
    key: "allStudentPhotosState",
    default: {}
})

export const previewStudentIdState = atom({
    key: "previewStudentIdState",
    default: null
})