import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { studioState } from "../../../stores/studioAtoms";
import TextButton from "../../../components/Button/TextButton";
import { updateSchoolInfo, getSchool, createEvent, getEvents } from "../../../services/apis/SchoolApi";
import { useParams } from "react-router-dom";
import SchoolEventList from "./components/SchoolEventList";

const SchoolAdminPage = () => {
    const [logoUrl, setLogoUrl] = useState("")
    const [homepageUrl, setHomepageUrl] = useState("")
    const [editLogo, setEditLogo] = useState(false)
    const [editHomepage, setEditHomepage] = useState(false)
    const [addingEvent, setAddingEvent] = useState(false)
    const [events, setEvents] = useState([])
    const { schoolId } = useParams();
    const studio = useRecoilValue(studioState);

    const handleLogoUrl = () => {
        var logoInput = document.getElementById("logoUrl")
        updateSchoolInfo(schoolId, { logo_url: logoInput.value }, studio).then(r => {
            if (r.status === 200) {
                setLogoUrl(r.data.logo_url)
                logoInput.value = ""
                setEditLogo(false)
            } else {
                console.log(r)
            }
        })
    }

    const handleHomepageUrl = () => {
        var homepageUrlInput = document.getElementById("homepageUrl")
        updateSchoolInfo(schoolId, { homepage_url: homepageUrlInput.value }, studio).then(r => {
            if (r.status === 200) {
                setHomepageUrl(r.data.homepage_url)
                homepageUrlInput.value = ""
                setEditHomepage(false)
            } else {
                console.log(r)
            }
        })
    }

    const getEventsHandler = () => {
        getEvents(schoolId).then(r => {
            if (r.status === 200) {
                setEvents(r.data)
            } else {
                console.log(r)
            }
        })
    }

    const addEvent = () => {
        var eventNameInput = document.getElementById("newEvent")
        createEvent(schoolId, { name: eventNameInput.value }, studio).then(r => {
            if (r.status === 201) {
                setAddingEvent(false)
                getEventsHandler()
            } else {
                console.log(r)
            }
        })
    }

    useEffect(() => {
        getSchool(schoolId).then(r => {
            if (r.status === 200) {
                // console.log(r.data.logo_url)
                setLogoUrl(r.data.logo_url)
                setHomepageUrl(r.data.homepage_url)
            } else {
                console.log(r)
            }
        })
        getEventsHandler()
        return () => { }
    }, [])

    return (
        <div>
            <h1 className="text-xl font-bold mb-4">학교 관리 페이지</h1>
            <div className="mb-3">
                <p className="mb-1">
                    학교 로고 URL <TextButton label={editLogo ? "취소" : "수정"} handleClick={() => {
                        setEditLogo(!editLogo)
                    }} />
                </p>
                {/* <p>{logoUrl}</p> */}
                {logoUrl ? <img className="w-20" src={logoUrl} /> : null}
                {
                    (!logoUrl || editLogo) ?
                        <>
                            <input
                                className="border w-full mb-1"
                                placeholder="학교 로고 URL"
                                id="logoUrl" />
                            <TextButton label="저장" handleClick={handleLogoUrl} />
                        </> : null
                }
            </div>
            <div>
                <p className="my-1">
                    학교 홈페이지 URL <TextButton label={editHomepage ? "취소" : "수정"} handleClick={() => {
                        setEditHomepage(!editHomepage)
                    }} />
                </p>
                <p>{homepageUrl}</p>
                {
                    (!homepageUrl || editHomepage) ?
                        <>
                            <input
                                className="border w-full mb-1"
                                placeholder="학교 홈페이지 URL"
                                id="homepageUrl" />
                            <TextButton label="저장" handleClick={handleHomepageUrl} />
                        </> : null
                }
            </div>
            <div className="mt-2">
                <p className="text-lg">학교 행사 관리 <TextButton label={addingEvent ? "취소" : "추가"} handleClick={() => {
                    setAddingEvent(!addingEvent)
                }} /></p>
                <p className="text-sm text-gray-500">행사별 앨범이 생성(삭제)됩니다.</p>
                {
                    addingEvent ?
                        <>
                            <input className="border w-full mb-1" placeholder="행사명" id="newEvent" />
                            <TextButton label="등록" handleClick={addEvent} />
                        </> : null
                }
                <SchoolEventList events={events} />
            </div>

        </div>
    )
}

export default SchoolAdminPage;