import Selector from "./Selector"
import { useRecoilState } from "recoil";
import { classroomState } from "../../stores/schoolAtoms"

const ClassroomSelector = ({ classrooms, handleChange }) => {
    const [classroom, setClassroom] = useRecoilState(classroomState)
    if (!Array.isArray(classrooms)) {
        console.error("classrooms is not an array")
        return null
    }

    const itemLabel = (item) => {
        return item.name + "반"
    }

    return (
        <Selector
            label={(classroom) ? classroom.name + "반" : "학급"}
            items={classrooms}
            handleChange={handleChange}
            itemLabel={itemLabel} />
    )
}

export default ClassroomSelector