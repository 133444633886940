import React, { useState, useEffect } from "react";
import { getSchools } from "../../../services/apis/StudioApi";
import Button from "../../../components/Button/Button"
import { useNavigate } from "react-router-dom";
import { schoolNameState, classroomState } from "../../../stores/schoolAtoms";
import { studioState } from "../../../stores/studioAtoms";
import { useSetRecoilState } from "recoil"
import SchoolSelector from "../../../components/Selector/SchoolSelector";
import Title from "../../../components/Title/Title";


const SelectSchoolPage = () => {
    const [schools, setSchools] = useState([]);
    const [school, setSchool] = useState(null)
    const setSchoolName = useSetRecoilState(schoolNameState)
    const setClassroom = useSetRecoilState(classroomState)
    const setStudio = useSetRecoilState(studioState)
    const navigate = useNavigate();
    const onSchoolSelect = (school) => {
        let schoolUrl = `/studio/school/${school.id}`
        setSchool(school)
        setSchoolName(school.name)
        navigate(schoolUrl)
    }

    useEffect(() => {
        setSchool(null)
        setSchoolName("")
        setClassroom("")
        getSchools().then(res => {
            if (res.status === 200) {
                setSchools(res.data)
            } else if (res.status === 401) {
                localStorage.removeItem("user")
                localStorage.removeItem("studioName")
                localStorage.removeItem("studioId")
                navigate("/studio/login")
            }
        })
        var studioName = JSON.parse(localStorage.getItem("studioName"))
        if (studioName) {
            setStudio(studioName)
        }
    }, [])

    const handleButtonClick = () => {
        navigate("/studio/school/register")
    }

    return (
        <div className="flex flex-col">
            <div className="flex-row flex justify-end mb-1">
                <div className="w-36">
                    <Button onClick={handleButtonClick} small={true}>+ 새로운 학교 등록</Button>
                </div>
            </div>
            {
                (schools.length === 0) ?
                    <p>먼저, 학교를 등록해주세요</p> :
                    <>
                        <Title label="학교 선택" />
                        <SchoolSelector schools={schools} onSchoolSelect={onSchoolSelect} />
                    </>
            }
        </div>
    )
}

export default SelectSchoolPage;