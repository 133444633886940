import React, { createContext, useState, useContext } from 'react';
import Snackbar from '../Snackbar/Snackbar';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({
        show: false,
        message: '',
    });

    const showSnackbar = (message) => {
        setSnackbar({ show: true, message });
        setTimeout(() => {
            setSnackbar({ show: false, message: '' });
        }, 3 * 1000); // 3초
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            {snackbar.show && <Snackbar message={snackbar.message} show={true} />}
        </SnackbarContext.Provider>
    );
};
