const camelToUnderscore = (key) => {
    return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}

const convertJson = (origin) => {
    const newObject = {};
    for (let camel in origin) {
        newObject[camelToUnderscore(camel)] = origin[camel];
    }
    return newObject;

}

module.exports = {
    camelToUnderscore,
    convertJson
}