export default function Button({ onClick, children, type = "button", color = "blue", disabled = false, small = false, disabled_label = "처리중" }) {
    if (disabled) {
        children = disabled_label
    }
    const enabledClass = `bg-${color}-600 hover:bg-${color}-500 text-white`
    const disabledClass = `bg-gray-600 text-white`
    const commonClasses = `flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 `
    return (
        <div className={`${small ? "" : "px-4"} w-full`}>
            <button
                className={commonClasses + (disabled ? disabledClass : enabledClass)}
                onClick={onClick}
                type={type}
                disabled={disabled}
            >
                {children}
            </button>
        </div>
    );
}
