import axios, { AxiosError } from "axios";
import { getAccessTokenFromStorage } from "./auth";


export const wsHost = process.env.REACT_APP_WS_HOST
export const host = process.env.REACT_APP_API_HOST
export const apiVersion = "v2"
export const apiUri = host + `api/${apiVersion}/`
export const schoolBase = apiUri + "school/"


const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    Authorization: `Bearer ${getAccessTokenFromStorage()}`,
    // "Cache-Control": "max-age=1, stale-while-revalidate=59",
  },
});

const instanceWithoutToken = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
});



instance.interceptors.request.use(
  function (config) {
    const accessToken = getAccessTokenFromStorage();
    config.headers.Authorization = `Bearer ${accessToken}`;
    // 요청을 보내기 전에 수행할 일
    return config;
  },
  function (error) {
    // 오류 요청을 보내기전 수행할 일
    return Promise.reject(error);
  },
);
const responseErrorHandler = (error) => {
  if (error.response) {
    // if (error.message === TIMEOUT_MESSAGE) {
    if (typeof window === "undefined") {
      // Redirect to /503 page in server side rendering
    } else {
      // console.error(error.response.status, "ERROR");
      // const { status } = error.response;
      // switch (status) {
      //   case 401:
      //     window.location.replace("/ㅣㅐ햐ㅜ");
      //     break;
      //   case 404:
      //     window.location.replace("/404");
      //     break;
      // }
    }
    // }
  }

  return Promise.reject(error);
};
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  responseErrorHandler,
  // (error) => {
  //   if (error.response.status === 401) {
  //     //place your reentry code
  //     window.location.href("http://localhost/login");
  //     console.log("401 error ");
  //   }
  //   console.error(error);
  // },
);

instanceWithoutToken.interceptors.request.use(
  function (config) {
    // 요청을 보내기 전에 수행할 일
    return config;
  },
  function (error) {
    // 오류 요청을 보내기전 수행할 일
    return Promise.reject(error);
  },
);
instanceWithoutToken.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => console.error(error),
);

export const requestMethod = {
  get: ({ endPoint, params, headers = {} }) => {
    return instance.get(endPoint, {
      // headers: headers,
      params: { ...params },
      validateStatus: function (status) {
        // 상태 코드가 500 이상일 경우 거부. 나머지(500보다 작은)는 허용.
        return status < 500;
      },
    });
  },
  post: ({ endPoint, body, headers }) => {
    if (headers === null) {
      headers = {
        "Content-Type": "application/json",
      }
    }
    return instance.post(endPoint, body, {
      headers: headers,
      validateStatus: function (status) {
        // 상태 코드가 500 이상일 경우 거부. 나머지(500보다 작은)는 허용.
        return status < 500;
      },
    });
  },
  patch: ({ endPoint, body, params }) => {
    return instance.patch(endPoint, body, { params: { ...params } });
  },
  delete: ({ endPoint }) => {
    return instance.delete(endPoint);
  },
  postFile: ({ endPoint, body }) => {
    return instance.post(endPoint, body, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
      validateStatus: function (status) {
        // 상태 코드가 500 이상일 경우 거부. 나머지(500보다 작은)는 허용.
        return status < 400;
      },
    });
  },
};

export const requestMethodWithoutToken = {
  get: ({ endPoint, params }) => {
    return instanceWithoutToken.get(endPoint, {
      params: { ...params },
      validateStatus: function (status) {
        // 상태 코드가 400 이상일 경우 거부. 나머지(400보다 작은)는 허용.
        return status < 400;
      },
    });
  },
  post: ({ endPoint, body, headers }) => {
    if (headers === null) {
      headers = {
        "Content-Type": "application/json",
      }
    }
    return instanceWithoutToken.post(endPoint, body, {
      headers: headers,
      validateStatus: function (status) {
        // 상태 코드가 500 이상일 경우 거부. 나머지(500보다 작은)는 허용.
        return status < 500;
      },
    });
  },
  patch: ({ endPoint, body, params }) => {
    return instanceWithoutToken.patch(endPoint, body, {
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...params },
    });
  },
  delete: ({ endPoint }) => {
    return instanceWithoutToken.delete(endPoint);
  },
};

export const requestServerSide = {
  get: ({ endPoint, params }) => {
    return axios.get(endPoint, {
      params: { ...params },
      validateStatus: function (status) {
        // 상태 코드가 400 이상일 경우 거부. 나머지(400보다 작은)는 허용.
        return status < 400;
      },
    });
  },
  post: ({ endPoint, body }) => {
    return axios.post(endPoint, body, {
      headers: {
        "Content-Type": "application/json",
      },
      validateStatus: function (status) {
        // 상태 코드가 500 이상일 경우 거부. 나머지(500보다 작은)는 허용.
        return status < 400;
      },
    });
  },
  patch: ({ endPoint, body, params }) => {
    return axios.patch(endPoint, body, {
      params: { ...params },
    });
  },
  delete: ({ endPoint }) => {
    return axios.delete(endPoint);
  },
};

export const requestDownload = async ({ schoolId, studentId }) => {
  var params = ""
  if (studentId) {
    params = `studentId=${studentId}`
  } else if (schoolId) {
    params = `schoolId=${schoolId}`
  }

  try {
    const response = await axios({
      method: 'get',
      url: apiUri + "photo/selected/download/?" + params,
      headers: {
        Authorization: `Bearer ${getAccessTokenFromStorage()}`,
      },
      responseType: 'blob',
    })

    const contentDisposition = response.headers['content-disposition'];
    // console.log(`headers: ${response.headers}`)
    let filename = 'download.zip'; // 기본 파일명
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, ''); // 따옴표 제거
      }
      filename = decodeURIComponent(filename)
      console.log(filename)
    }

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', `${filename}.zip`)

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)

  } catch (error) {
    console.error('Download Error: ', error)
  }
}
