const FileUploader = ({ onChange, setFile }) => {
    const handleDragOver = (e) => {
        e.preventDefault(); // 기본 이벤트를 방지합니다.
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        setFile(files[0]);
    };

    return (
        <div className="flex items-center justify-center w-full mt-2"
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <p className="text-md text-gray-400 mb-3">명렬표 업로드</p>
                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 mx-6">
                        <span className="font-semibold">클릭해서 업로드하거나 </span>
                        파일을 끌어다 놓으세요</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">.xlsx</p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={onChange} />
            </label>
        </div>
    )

}

export default FileUploader