import BigButton from "../../components/Button/BigButton";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { classroomState } from "../../stores/schoolAtoms"
import { useEffect } from "react";

const SelectMenuPage = () => {
    const navigate = useNavigate();
    const setClassroom = useSetRecoilState(classroomState)

    useEffect(() => {
        setClassroom(null)
    }, [])

    return (
        <div>
            <div className="mb-8">
                <BigButton handleClick={() => navigate(`info`)} label="학교 관리" />
            </div>
            <div className="mb-8">
                <BigButton handleClick={() => navigate('login')} label="학급 관리" />
            </div>
            <div className="mb-8">
                <BigButton handleClick={() => navigate('board/write')} label="학교 게시글 작성" />
            </div>
        </div>
    )
}

export default SelectMenuPage