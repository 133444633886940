import React, { useState, useEffect } from 'react';
import jsonData from '../../../utils/korean_regions.json'
import { registerSchool } from '../../../services/apis/StudioApi';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../components/Layout/SnackbarProvider';
import './RegisterSchoolPage.css';



const SchoolRegistrationForm = () => {
    const navigate = useNavigate()
    const currentYear = new Date().getFullYear();
    const { showSnackbar } = useSnackbar();
    const [schoolData, setSchoolData] = useState({
        region: '',
        district: '',
        name: '',
        grade: 3,
        classCount: '',
        contractYear: currentYear,
    });
    const [districts, setDistricts] = useState(['시/군/구']);
    const [submitable, setSubmitable] = useState(false)

    const regions = ['지역', ...Object.keys(jsonData)]

    const handleChange = (e) => {
        setSchoolData({ ...schoolData, [e.target.name]: e.target.value });
        if (e.target.name === 'region') {
            if (e.target.value === '지역') {
                setDistricts(['시/군/구'])
            } else {
                setDistricts(jsonData[e.target.value])
            }
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        registerSchool(schoolData).then(res => {
            console.log(res.data)
            if (res.status == 201) {
                navigate('/studio/school/')
            } else {
                showSnackbar('요청을 처리하지 못했습니다')
            }
        })
    };

    useEffect(() => {
        if (schoolData.district === '' && districts.length > 1) {
            setSchoolData({ ...schoolData, district: jsonData[schoolData.region][0] })
        }
    }, [districts])

    useEffect(() => {
        if (schoolData.region && schoolData.district && schoolData.name && schoolData.classCount) {
            setSubmitable(true)
        } else {
            setSubmitable(false)
        }
    }, [schoolData])

    return (
        <form onSubmit={handleSubmit} className="school-registration-form">
            <h1>학교 등록</h1>

            <label>
                지역:
                <select name="region" value={schoolData.region} onChange={handleChange}>
                    {regions.map(region => (
                        <option key={region} value={region}>{region}</option>
                    ))}
                </select>
            </label>

            <label>
                시/군/구:
                <select name="district" value={schoolData.district} onChange={handleChange}>
                    {districts.map(district => (
                        <option key={district} value={district}>{district}</option>
                    ))}
                </select>
            </label>

            <label>
                이름:
                <input
                    type="text"
                    name="name"
                    placeholder='예) 서울고등학교'
                    value={schoolData.name}
                    onChange={handleChange}
                />
            </label>

            <label>
                학년:
                <select name='grade' value={schoolData.grade} onChange={handleChange}>
                    {[1, 2, 3, 4, 5, 6].map((grade) => (
                        <option key={grade} value={grade}>{grade}학년</option>
                    ))}
                </select>
            </label>

            <label>
                학급 수:
                <input type="number" name="classCount" value={schoolData.classCount} onChange={handleChange} />
            </label>

            <label>
                계약연도:
                <select name='contractYear' value={schoolData.contractYear} onChange={handleChange}>
                    <option key={currentYear} value={currentYear}>{currentYear}년</option> {/* 올해 */}
                    <option key={currentYear - 1} value={currentYear - 1}>작년({currentYear - 1})</option> {/* 작년 */}
                    <option key={currentYear + 1} value={currentYear + 1}>내년({currentYear + 1})</option> {/* 내년 */}
                </select>
            </label>

            <button type="submit" disabled={!submitable}>등록</button>
        </form>
    );
};

export default SchoolRegistrationForm;
