import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useParams, useNavigate } from 'react-router-dom';
import { getSchool, getClassNames, teacherLogin } from "../../../services/apis/SchoolApi";
import { schoolNameState, classroomState } from "../../../stores/schoolAtoms"
import { studioState } from "../../../stores/studioAtoms";
import SchoolClassroomSelector from "../../../components/Selector/ClassroomSelector";



const TeacherLoginPage = () => {
    const { schoolId } = useParams();
    const [schoolName, setSchoolName] = useRecoilState(schoolNameState)
    const [schoolImgUrl, setSchoolImgUrl] = useState("/images/eALBUM-logo-icon.png")
    const [classroom, setClassroom] = useRecoilState(classroomState)
    const studio = useRecoilValue(studioState)
    const [classrooms, setclassrooms] = useState([])
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [loginError, setLoginError] = useState("")

    const togglePasswordVisiblity = () => {
        setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
    };

    const navigate = useNavigate();

    const getSchoolInfo = async () => {
        let res = await getSchool(schoolId)
        return res.data
    }

    const callClassrooms = async () => {
        let res = await getClassNames(schoolId)
        return res.data
    }

    const handleChange = (value) => {
        setClassroom(value)
    }

    useEffect(() => {
        if (classroom == null || classroom.id == null) return
        navigate("/school/" + schoolId + "/class/" + classroom.id + '/students')
    }, [classroom])


    useEffect(() => {
        getSchoolInfo().then(data => {
            setSchoolName(data.name);
            if (data.logoUrl) {
                setSchoolImgUrl(data.logoUrl)
            }
        });
        callClassrooms().then(classes => {
            setclassrooms(classes)
            setClassroom(null)
        }).then(() => {
            // var teacher = JSON.parse(localStorage.getItem("teacher"))
            // if (teacher) {
            //     setClassroom(teacher.classroom)
            //     document.getElementById("password").value = teacher.password
            //     setPassword(teacher.password)
            // }
        });
    }, []);


    const login = async () => {
        let res = await teacherLogin({
            "school": schoolId,
            "class_room": classroom.id,
            "password": password,
        });
        if (res.status === 200) {
            // store teacher id & password to localstorage
            localStorage.setItem("teacher", JSON.stringify({
                "id": res.data,
                "password": password,
                "classroom": classroom,
            }))
            setClassroom(classroom)
            navigate("/school/" + schoolId + "/class/" + classroom.id + "/students")
        } else if (res.status === 401) {
            setLoginError("비밀번호를 확인해주세요")
        }
    }


    const onButtonClick = () => {
        // Set initial error values to empty
        setPasswordError("")
        if (studio) {
            setClassroom(classroom)
            navigate("/school/" + schoolId + "/class/" + classroom.id)
            return
        }
        if ("" === password) {
            setPasswordError("비밀번호를 입력해주세요.")
            return
        }

        if (password.length < 2) {
            setPasswordError("비밀번호를 확인해주세요")
            return
        }
        login()
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onButtonClick();
        }
    };

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-10 w-auto"
                        src={schoolImgUrl}
                        alt="School logo"
                    />
                    <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        {schoolName}
                    </h2>
                </div>


                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="space-y-6">
                        <SchoolClassroomSelector classrooms={classrooms} handleChange={handleChange} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeacherLoginPage;