import { apiUri, requestMethod, requestMethodWithoutToken } from './common';
import { convertJson } from '../../utils/convert_helper';
import { getAccessTokenFromStorage } from "./auth";
import axios, { AxiosError } from "axios";

const schoolUri = "school/"
const sceneUri = "scenes/"
const studioUri = "studio/"
const shotUri = "phototimerecords/"
const selectPhotoScheduleUri = "photo-select-schedules/"

export const login = (email, password) => {
    console.log(apiUri)
    return requestMethodWithoutToken.post({ endPoint: apiUri + 'token/', body: { email, password } })
}

export const getMe = () => {
    return requestMethod.get({ endPoint: apiUri + "users/me/" })
}

const registerSchool = (schoolData) => {
    return requestMethod.post({ endPoint: apiUri + schoolUri, body: convertJson(schoolData) })
}

const getSchools = () => {
    return requestMethod.get({ endPoint: apiUri + schoolUri })
}

const getScenes = (schooId) => {
    return requestMethod.get({ endPoint: apiUri + sceneUri, params: { school_id: schooId } })
}

const createScene = (sceneData) => {
    return requestMethod.post({ endPoint: apiUri + sceneUri, body: convertJson(sceneData) })
}

const startPhoto = (shotData) => {
    return requestMethod.post({ endPoint: apiUri + shotUri, body: convertJson(shotData) })
}

const endPhoto = (shotId) => {
    let data = {
        end_time: new Date().toISOString()
    }
    return requestMethod.patch({
        endPoint: apiUri + shotUri + shotId + "/", body: convertJson(data)
    })
}

const uploadPhotos = (formData, { setProgress }) => {
    return axios.post(apiUri + "photo/upload/", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getAccessTokenFromStorage()}`,
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
        }
    })
}

const getUploadedPhotos = (schoolId, classroomId) => {
    return requestMethod.get({ endPoint: apiUri + `photo/upload?schoolId=${schoolId}&classroomId=${classroomId}` })
}

export const deleteUploadedPhoto = (photoId) => {
    return requestMethod.delete({ endPoint: apiUri + `photo/upload/?photoId=${photoId}` })
}

export const getSelectedPhotos = (schoolId) => {
    return requestMethod.get({ endPoint: apiUri + `selected-photos/?schoolId=${schoolId}` })
}

export const getTeahcersInfo = (schoolId) => {
    return requestMethod.get({ endPoint: apiUri + `studio/teachers/${schoolId}/` })
}

export const getTempPassword = () => {
    return requestMethod.get({ endPoint: apiUri + `temp-password/` })
}

export const createTempPassword = (available_on) => {
    return requestMethod.post({ endPoint: apiUri + `temp-password/`, body: { available_on } })
}

export const freelancerLogin = (data) => {
    return requestMethodWithoutToken.post({ endPoint: apiUri + `freelancer-login/`, body: convertJson(data) })
}

export const getPhotoSelectSchedule = (schoolId) => {
    return requestMethod.get({ endPoint: apiUri + selectPhotoScheduleUri + `${schoolId}/` })
}

export const startSelectPhoto = (schoolId) => {
    return requestMethod.post({ endPoint: apiUri + selectPhotoScheduleUri + `${schoolId}/start/` })
}

export const endSelectPhoto = (schoolId) => {
    return requestMethod.post({ endPoint: apiUri + selectPhotoScheduleUri + `${schoolId}/end/` })
}

export const getStudentUnselectedPhotos = (studentId) => {
    return requestMethod.get({ endPoint: apiUri + `photo/manual-select/?studentId=${studentId}` })
}

export const selectStudentPhoto = (data) => {
    return requestMethod.post({ endPoint: apiUri + `photo/manual-select/`, body: convertJson(data) })
}

export const getUnmatchedPhotos = (schoolId) => {
    return requestMethod.get({ endPoint: apiUri + `photo/manual-matching/?schoolId=${schoolId}` })
}

export const matchPhoto = (data) => {
    return requestMethod.post({ endPoint: apiUri + `photo/manual-matching/`, body: convertJson(data) })
}

export const getSchoolStudents = (schoolId) => {
    return requestMethod.get({ endPoint: apiUri + `school/${schoolId}/students/` })
}

export const getClassroomStudentsAPI = (schoolId, classroomId) => {
    return requestMethod.get({ endPoint: apiUri + `school/${schoolId}/students/?classroomId=${classroomId}` })
}

export const requestChangePassword = (data) => {
    return requestMethod.post({ endPoint: apiUri + `studio/new-password/`, body: convertJson(data) })
}

export const getAllSchoolFileCondition = (schoolId) => {
    return requestMethod.get({ endPoint: apiUri + `photo/selected/all-school-file/?schoolId=${schoolId}` })
}

export const createAllSchoolFile = (data) => {
    return requestMethod.post({ endPoint: apiUri + `photo/selected/download/`, body: convertJson(data) })
}

export const fetchCurrentTime = () => {
    return requestMethod.get({ endPoint: apiUri + `time/` })
}

export { registerSchool, getSchools, getScenes, createScene, startPhoto, endPhoto, uploadPhotos, getUploadedPhotos }