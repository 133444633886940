import { useNavigate, useParams } from "react-router-dom"
import { createSchoolPost } from "../../../services/apis/SchoolApi"
import { useState } from "react"
import { useSnackbar } from "../../../components/Layout/SnackbarProvider"

export default function SchoolPostWritePage() {
    const { schoolId } = useParams()
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const { showSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const submitPost = async () => {
        var data = {
            title: title,
            content: content,
            school_id: schoolId,
        }
        var res = await createSchoolPost(schoolId, data)
        if (res.status === 201) {
            showSnackbar("게시글 생성 완료")
            navigate(`/school/${schoolId}/`)
        } else {
            showSnackbar("게시글 생성 오류")
        }
    }

    return (
        <>
            <div className="text-align-start w-80">
                <label htmlFor="post" className="">
                    새 학교 게시물 작성
                </label>
                <p className="text-xs text-red-700">
                    작성 후 편집/삭제가 어려우니 오탈자에 유의하여 작성해주세요.
                </p>
                <div className="mt-2">
                    <input type="text" id="title" placeholder="제목" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className="mt-2">
                    <textarea
                        rows={5}
                        name="post"
                        id="post"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="내용"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </div>
            </div>
            <div className="mt-2 flex justify-end">
                <button
                    onClick={submitPost}
                    className="inline-flex items-end rounded-md bg-midnight-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-midnight-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    등록
                </button>
            </div>
        </>
    )
}
