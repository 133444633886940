import { selector } from "recoil";
import {
    currentStudentState,
    currentClassStudentsState,
    modalOnState,
    sceneState,
    shotState,
    imagesState,
    studioState,
} from "./studioAtoms";


export const currentStudentSelector = selector({
    key: "currentStudentSelector",
    get: ({ get }) => {
        return get(currentStudentState)
    }
})

export const currentClassStudentsSelector = selector({
    key: "currentClassStudentsSelector",
    get: ({ get }) => {
        return get(currentClassStudentsState)
    }
})

export const modalOnSelector = selector({
    key: "modalOnSelector",
    get: ({ get }) => {
        return get(modalOnState)
    }
})

export const sceneSelector = selector({
    key: "sceneSelector",
    get: ({ get }) => {
        return get(sceneState)
    }
})

export const shotSelector = selector({
    key: "shotSelector",
    get: ({ get }) => {
        return get(shotState)
    }
})

export const imagesSelector = selector({
    key: "imagesSelector",
    get: ({ get }) => {
        return get(imagesState)
    }
})

export const studioSelector = selector({
    key: "studioSelector",
    get: ({ get }) => {
        return get(studioState)
    }
})
