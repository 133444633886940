import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
    modalOnState,
    selectedPhotoState,
    studentsState,
    selectedStudentState,
    studentPhotosState,
    selectedPhotoIndexState,
} from '../../stores/studioAtoms';
import { modalOnSelector } from '../../stores/studioSelectors';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import StudentSelector from '../Selector/StudentSelector';
import TextButton from '../Button/TextButton';
import { matchPhoto, deleteUploadedPhoto } from '../../services/apis/StudioApi';
import { useSnackbar } from '../Layout/SnackbarProvider';
import { getTimeOnly } from '../../utils/datetime_formats';


const PhotoPreviewModal = ({ currentStudent, imgUrl, setNeedUpdate }) => {
    const setModalOn = useSetRecoilState(modalOnState);
    const modalOn = useRecoilValue(modalOnSelector)
    const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentState);
    const [selectedPhoto, setSelectedPhoto] = useRecoilState(selectedPhotoState);
    const studentPhotos = useRecoilValue(studentPhotosState)
    const [studentPhotoIdx, setStudentPhotoIdx] = useRecoilState(selectedPhotoIndexState)
    const students = useRecoilValue(studentsState)
    const [onProgress, setOnProgress] = useState(false)
    const { showSnackbar } = useSnackbar();

    const loadPrevPhoto = () => {
        if (studentPhotoIdx === 0) return;
        setStudentPhotoIdx(studentPhotoIdx - 1)
    }

    const loadNextPhoto = () => {
        if (studentPhotoIdx === studentPhotos.length - 1) return;
        setStudentPhotoIdx(studentPhotoIdx + 1)
    }

    function closeModal() {
        setModalOn(false)
    }

    useEffect(() => {
        setSelectedPhoto(studentPhotos[studentPhotoIdx])
    }, [studentPhotoIdx])

    return (
        <Transition appear show={modalOn} as={Fragment}>
            <Dialog as="div" className="relative z-10 justify-center" onClose={closeModal}>
                <Dialog.Overlay className="fixed inset-0 bg-gray opacity-50" />
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >

                            <div className="fixed inset-0 flex items-center justify-center p-4">
                                <Dialog.Panel
                                    className="bg-white rounded max-w-md mx-auto transform overflow-visible text-center align-middle shadow-xl transition-all border-2 border-black">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-blue-900 mt-3 flex px-4"
                                    >
                                        <TextButton
                                            label="이전"
                                            enabled={studentPhotoIdx > 0}
                                            handleClick={loadPrevPhoto}
                                        />
                                        <p className='flex-grow'>{currentStudent ? currentStudent.number : 0}번 {currentStudent ? currentStudent.name : ""}</p>
                                        <TextButton
                                            label="다음"
                                            enabled={studentPhotoIdx < studentPhotos.length - 1}
                                            handleClick={loadNextPhoto}
                                        />
                                    </Dialog.Title>
                                    <div className='p-4'>
                                        <img src={imgUrl} alt="원본 사진" />
                                    </div>
                                    <div>
                                        촬영시각 {selectedPhoto ? getTimeOnly(selectedPhoto.taken_at) : "정보없음"}
                                    </div>
                                    <div className='flex flex-row items-center justify-center gap-x-3'>
                                        <StudentSelector students={students} handleStudentSelect={(value) => {
                                            setSelectedStudent(value)
                                        }} />
                                        <TextButton label="저장"
                                            loading={onProgress}
                                            handleClick={() => {
                                                setOnProgress(true)
                                                var data = {
                                                    "photo_id": selectedPhoto.id,
                                                    "student_id": selectedStudent.id,
                                                }
                                                matchPhoto(data).then(res => {
                                                    if (res.status === 200) {
                                                        setNeedUpdate(true)
                                                        showSnackbar("재지정 완료")
                                                        closeModal()
                                                    } else {
                                                        showSnackbar("재지정 실패")
                                                    }
                                                    setOnProgress(false)
                                                })
                                            }} />
                                        <TextButton
                                            label="삭제"
                                            bgColor='bg-red-500'
                                            loading={onProgress}
                                            handleClick={() => {
                                                setOnProgress(true)
                                                deleteUploadedPhoto(selectedPhoto.id).then(res => {
                                                    if (res.status === 204) {
                                                        setNeedUpdate(true)
                                                        showSnackbar("삭제 완료")
                                                        closeModal()
                                                    } else {
                                                        showSnackbar("삭제 실패")
                                                    }
                                                    setOnProgress(false)
                                                })
                                            }} />
                                    </div>
                                    <div className="mt-4 flex justify-center">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center border border-gray bg-white border-gray-400 px-9 py-1 mb-3 text-sm text-gray-500 font-medium text-blue-900 hover:bg-midnight-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            닫기
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default PhotoPreviewModal;
