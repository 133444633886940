import { useRecoilValue } from "recoil"
import { imagesSelector } from "../../stores/studioSelectors"
import { PaperClipIcon } from '@heroicons/react/20/solid'

const ImageList = () => {
    const images = useRecoilValue(imagesSelector)
    let liClass = "flex justify-between gap-x-6 py-5 px-3 cursor-pointer hover:bg-gray-100"

    return (
        <ul role="list" className="divide-y divide-gray-100">
            {images && images.map((image, imageIdx) => {
                return (
                    <li key={image.name} className={liClass}>
                        <div className="flex min-w-0 gap-x-4">
                            <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{image.name}
                                    <span className="ml-3 mt-1 truncate text-xs leading-5 text-gray-500">{(image.size / 1024 / 1024).toFixed(1)}mb</span></p>
                            </div>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

export default ImageList;