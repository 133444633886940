import { getUploadedPhotos, getUnmatchedPhotos, getSchoolStudents, getClassroomStudentsAPI } from "../../../../services/apis/StudioApi"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    imagesState,
    modalOnState,
    unmatchedPhotosState,
    selectedPhotoState,
    studentsState,
    selectedStudentState,
} from "../../../../stores/studioAtoms";
import PhotoPreviewModal from "../../../../components/Modal/PhotoModal";
import MatchPhotoModal from "../../../../components/Modal/MatchPhotoModal";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../../components/Tooltip/Tooptip";
import { RadioGroup } from '@headlessui/react'
import { getTimeOnly } from "../../../../utils/datetime_formats";
import { getClassNames } from "../../../../services/apis/SchoolApi";
import UnmatchedPhotos from "./UnmatchedPhotos"
import StudentPhotoItem from "./StudentPhotoItem"


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const StudentPhotoList = () => {
    const [resData, setResData] = useState({});
    const selectedStudent = useRecoilValue(selectedStudentState);
    const selectedPhoto = useRecoilValue(selectedPhotoState);
    const { schoolId } = useParams();
    const images = useRecoilValue(imagesState)
    const [modalOn, setModalOn] = useRecoilState(modalOnState)
    const [unmatchedPhotos, setUnmatchedPhotos] = useRecoilState(unmatchedPhotosState)
    const [matchModalOn, setMatchModalOn] = useState(false)
    const [classrooms, setClassrooms] = useState([])
    const setStudents = useSetRecoilState(studentsState)
    const [onLoading, setOnLoading] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(false)
    const [viewClass, setViewClass] = useState(null)

    const setUploadedPhotosItem = async () => {
        setOnLoading(true)
        if (viewClass === null) return
        if (viewClass === 0) {
            for (var i = 0; i < classrooms.length; i++) {
                var res = await getUploadedPhotos(schoolId, classrooms[i].id)
                if (res.status === 200) {
                    // console.log(res.data)
                    setResData(resData => {
                        const updatedResData = { ...resData };

                        Object.keys(res.data).forEach(key => {
                            updatedResData[key] = Object.assign({}, resData[key], res.data[key]);
                        });

                        return updatedResData;
                    })
                } else {
                    console.log(res.status)
                    console.log(res.data)
                }
            }
        } else {
            var res = await getUploadedPhotos(schoolId, viewClass)
            if (res.status === 200) {
                // console.log(res.data)
                setResData(res.data)
            } else {
                console.log(res.status)
                console.log(res.data)
            }
        }
        setOnLoading(false)
    }

    const getClassrooms = async () => {
        var res = await getClassNames(schoolId)
        if (res.status === 200) {
            setClassrooms(res.data)
        } else {
            console.log(res.status)
            console.log(res.data)
        }
    }

    const getClassroomStudents = async (classroomId) => {
        var res = await getClassroomStudentsAPI(schoolId, classroomId)
        if (res.status === 200) {
            // console.log(res.data)
            return res.data
        } else {
            console.log(res.status)
            console.log(res.data)
        }
    }


    const getAllClassroomStudents = async () => {
        var newStudents = []
        for (var i = 0; i < classrooms.length; i++) {
            newStudents.push(...await getClassroomStudents(classrooms[i].id))
        }
        setStudents(newStudents)
    }

    useEffect(() => {
        if (images.length == 0) {
            setUploadedPhotosItem()
            getUnmatchedPhotos(schoolId).then(res => {
                if (res.status === 200) {
                    setUnmatchedPhotos(res.data)
                } else if (res.status === 204) {
                    setUnmatchedPhotos([])
                }
            })
        }
    }, [images])

    useEffect(() => {
        if (!modalOn && needUpdate) {
            setUploadedPhotosItem()
            setNeedUpdate(false)
        }
    }, [modalOn, needUpdate])

    useEffect(() => {
        if (classrooms.length == 0) return
        if (viewClass != null) {
            setViewClass(classrooms[0].id)
        }
    }, [classrooms])

    useEffect(() => {
        if (viewClass == null) return
        if (viewClass === 0) {
            getAllClassroomStudents()
        } else {
            getClassroomStudents(viewClass).then(res => {
                setStudents(res)
            })
        }
        setUploadedPhotosItem()
    }, [viewClass])

    useEffect(() => {
        getClassrooms()
    }, [])

    const setListItems = () => {
        let listItems = []
        Object.entries(resData).forEach(([classroom, students]) => {
            Object.entries(students).forEach(([number, studentData]) => {
                listItems.push(
                    <StudentPhotoItem
                        key={`${number}-${studentData.student.id}`}
                        classroom={classroom}
                        number={number}
                        studentData={studentData} />
                )
            })
        })
        return listItems;
    }

    return (
        <div className="border p-4 rounded-lg shadow">
            {onLoading ? <div className="bg-midnight-400 text-white text-center rounded py-2">이미지 로딩중</div> : null}
            <div className="hover:cursor-pointer hover:bg-gray-100" onClick={() => {
                setMatchModalOn(true)
                setModalOn(true)
            }}>
                <UnmatchedPhotos unmatchedPhotos={unmatchedPhotos} />
            </div>
            <div id="class-radio" className="mt-2">
                <div className="flex items-center justify-between">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">반별보기</h2>
                </div>

                <RadioGroup value={viewClass} onChange={setViewClass} className="mt-2">
                    <RadioGroup.Label className="sr-only">학급 선택</RadioGroup.Label>
                    <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                        {classrooms.map((clsroom) => (
                            <RadioGroup.Option
                                key={clsroom.name}
                                value={clsroom.id}
                                className={({ active, checked }) =>
                                    classNames(
                                        'cursor-pointer focus:outline-none',
                                        active ? 'ring-2 ring-midnight-600 ring-offset-2' : '',
                                        checked
                                            ? 'bg-midnight-600 text-white hover:bg-midnight-500'
                                            : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                                        'flex items-center justify-center rounded-md py-3 px-1 text-sm font-semibold uppercase sm:flex-1'
                                    )
                                }
                            >
                                <RadioGroup.Label as="span">{clsroom.name}반</RadioGroup.Label>
                            </RadioGroup.Option>
                        ))}
                        <RadioGroup.Option
                            value={0}
                            className={({ active, checked }) =>
                                classNames(
                                    'cursor-pointer focus:outline-none',
                                    active ? 'ring-2 ring-midnight-600 ring-offset-2' : '',
                                    checked
                                        ? 'bg-midnight-600 text-white hover:bg-midnight-500'
                                        : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1'
                                )
                            }
                        >
                            <RadioGroup.Label as="span">전체보기</RadioGroup.Label>
                        </RadioGroup.Option>
                    </div>
                </RadioGroup>
            </div>
            <ul role="list" className="divide-y divide-gray-100">
                {setListItems()}
            </ul>
            {(selectedPhoto && !matchModalOn) ?
                <PhotoPreviewModal currentStudent={selectedStudent} imgUrl={selectedPhoto.original} setNeedUpdate={setNeedUpdate} /> : null}
            {matchModalOn ?
                <MatchPhotoModal closeAction={() => { setMatchModalOn(false) }} setNeedUpdate={setNeedUpdate} /> : null}

        </div>
    )
}

export default StudentPhotoList;