import { apiUri, schoolBase, requestMethod, requestMethodWithoutToken } from './common';
import { convertJson } from '../../utils/convert_helper';


const getSchool = (pk) => {
    return requestMethodWithoutToken.get({ endPoint: schoolBase + pk + "/" })
}

const getClassNames = (pk) => {
    return requestMethodWithoutToken.get({ endPoint: schoolBase + pk + "/class/" })
}

const teacherLogin = (data) => {
    return requestMethodWithoutToken.post({ endPoint: schoolBase + "login/", body: convertJson(data) })
}

export const getStudentListForStudio = (schoolId, classroomId) => {
    return requestMethod.get({
        endPoint: schoolBase + schoolId + "/class/" + classroomId + "/students/",
    })
}

const uploadStudentList = (schoolId, classroomId, studio, formData) => {
    var method;
    if (studio) {
        method = requestMethod
    } else {
        method = requestMethodWithoutToken
    }
    console.log(method)
    return method.post({
        endPoint: schoolBase + schoolId + "/class/" + classroomId + "/students/upload/",
        body: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}

export const updateStudent = (schoolId, classroomId, student) => {
    return requestMethodWithoutToken.patch({
        endPoint: schoolBase + schoolId + "/class/" + classroomId + "/students/" + student.id + "/",
        body: convertJson(student)
    })
}

export const addStudent = (schoolId, classroomId, student) => {
    return requestMethodWithoutToken.post({
        endPoint: schoolBase + schoolId + "/class/" + classroomId + "/students/",
        body: convertJson(student)
    })
}

export const updateSchoolInfo = (schoolId, data, studio) => {
    var method;
    if (studio) {
        method = requestMethod
    } else {
        method = requestMethodWithoutToken
    }
    return method.patch({
        endPoint: schoolBase + schoolId + "/",
        body: data
    })
}

export const getEvents = (schoolId) => {
    return requestMethodWithoutToken.get({ endPoint: schoolBase + schoolId + "/events/" })
}

export const createEvent = (schoolId, data, studio) => {
    var method;
    console.log("create event studio", studio)
    if (studio) {
        method = requestMethod
    } else {
        method = requestMethodWithoutToken
    }
    return method.post({
        endPoint: schoolBase + schoolId + "/events/",
        body: data
    })
}

export const createSchoolPost = (schoolId, data) => {
    return requestMethod.post({
        endPoint: `${schoolBase}posts/?web=true`,
        body: data
    })
}

export { getSchool, getClassNames, teacherLogin, uploadStudentList }