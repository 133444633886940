import Selector from "./Selector";
import { selectedStudentState } from '../../stores/studioAtoms';
import { useRecoilState } from 'recoil';


const StudentSelector = ({ students, handleStudentSelect }) => {
    const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentState);

    const itemLabel = (student) => {
        return (
            <div>
                {student.classroom}반 {student.number}번 {student.name}
            </div>
        )
    }

    return (
        <Selector
            label={selectedStudent ? `${selectedStudent.classroom}반 ${selectedStudent.number}번 ${selectedStudent.name}` : "학생"}
            items={students}
            handleChange={handleStudentSelect}
            itemLabel={itemLabel}
        />
    )
}


export default StudentSelector;