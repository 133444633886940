import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { createScene } from "../../services/apis/StudioApi";
import "react-datepicker/dist/react-datepicker.css";


const CreateScenePage = () => {
    const [shotOn, setShotOn] = useState(new Date());
    const [sceneName, setSceneName] = useState("")
    const { schoolId } = useParams();
    const [error, setError] = useState("")
    const [selectN, setSelectN] = useState(1)
    const navigate = useNavigate();

    const requestCreateScene = () => {
        if (sceneName === "") {
            alert("촬영 이름을 입력해주세요.")
            return
        }
        var studio_id = JSON.parse(localStorage.getItem("studioId"))
        let sceneData = {
            "name": sceneName,
            "shot_on": shotOn,
            "studio": studio_id,
            "school": schoolId,
            "select_n": selectN,
        }
        // console.log(sceneData)
        createScene(sceneData)
            .then(res => {
                console.log(res)
                navigate(`/studio/school/${schoolId}/scene`)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleCancelButton = () => {
        navigate(-1)
    }

    useEffect(() => {
        if (sceneName.includes("/")) {
            setError("촬영 이름에 '/' 는 사용할 수 없습니다.")
        } else {
            setError("")
        }
    }, [sceneName])


    return (
        <>
            <form>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-2xl font-semibold leading-7 text-gray-900">
                            촬영 추가</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            새로운 촬영 내용을 추가해주세요.
                        </p>

                        <div className="mt-10 grid grid-cols-3 gap-x-6 gap-y-8 ">
                            <div className="col-span-1">
                                <label htmlFor="name" className="block text-sm font-bold leading-6 text-gray-900 pt-1.5">
                                    촬영 이름
                                </label>

                            </div>
                            <div className="col-span-2">
                                <div className="">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            autoComplete="name"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="예) 실내 프로필"
                                            onChange={(e) => setSceneName(e.target.value)}
                                        />
                                    </div>
                                    <div className="text-xs text-red pl-3">
                                        {error}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-span-1">
                                <label className="block text-sm font-bold leading-6 text-gray-900 pt-1.5">
                                    촬영일
                                </label>
                            </div>
                            <div className="col-span-2">
                                <div className="">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <DatePicker
                                            showIcon
                                            onChange={setShotOn}
                                            selected={shotOn}
                                            dateFormat="yyyy.MM.dd"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-span-1">
                                <label htmlFor="select-n" className="block text-sm font-bold leading-6 text-gray-900 pt-1.5">
                                    선택 장수
                                </label>
                            </div>
                            <div className="col-span-2">
                                <div className="">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            type="number"
                                            name="select-n"
                                            id="select-n"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            value={selectN}
                                            onChange={(e) => setSelectN(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        className="rounded-md text-sm font-semibold leading-6 px-8 py-1 text-gray-900 hover:bg-midnight-100"
                        onClick={handleCancelButton}
                    >
                        취소
                    </button>
                    <button
                        type="button"
                        disabled={error !== ""}
                        className="rounded-md bg-midnight-600 disabled:bg-midnight-400 disabled:cursor-not-allowed px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-midnight-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midnight-600"
                        onClick={requestCreateScene}
                    >
                        등록
                    </button>
                </div>
            </form>
        </>
    )
}

export default CreateScenePage;
