import { getSelectedPhotos } from "../../../../services/apis/StudioApi"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { imagesState, modalOnState, selectedStudentState } from "../../../../stores/studioAtoms";
import TextButton from "../../../../components/Button/TextButton";
import { requestDownload } from "../../../../services/apis/common";
import SelectPhotoModal from "../../../../components/Modal/SelectPhotoModal";


const StudentPhotoList = () => {
    const [resData, setResData] = useState({});
    const { schoolId } = useParams();
    const [images, setImages] = useRecoilState(imagesState)
    const [loadingState, setLoadingState] = useState({});
    const [modalOn, setModalOn] = useRecoilState(modalOnState)
    const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentState);

    useEffect(() => {
        if (images.length === 0) {
            getSelectedPhotos(schoolId).then(res => {
                setResData(res.data);
            })
        }
    }, [images])

    useEffect(() => {
        if (!modalOn) {
            getSelectedPhotos(schoolId).then(res => {
                // console.log(res.data)
                setResData(res.data);
            })
        }
    }, [modalOn])

    const handleDownload = (studentId) => {
        setLoadingState(prev => ({ ...prev, [studentId]: true }));
        requestDownload({ studentId })
            .then(() => {
                // 성공적으로 처리된 후의 작업 (상태 업데이트 등)
            })
            .catch(error => {
                // 에러 처리
            })
            .finally(() => {
                setLoadingState(prev => ({ ...prev, [studentId]: false }));
            });
    };



    const setListItems = () => {
        let listItems = []
        Object.entries(resData).forEach(([classroom, students]) => {
            Object.entries(students).forEach(([number, studentData]) => {
                // console.log(studentData.images.length)
                // console.log(studentData)
                listItems.push(
                    <li
                        key={studentData.student.id}
                        className="flex-col justify-between gap-x-6 py-5 hover:bg-gray-100 px-2"
                        onClick={() => null}
                    >
                        <div className="flex flex-row min-w-0 justify-between">
                            <div className="flex flex-row gap-x-4">
                                <p>{classroom}반</p>
                                <p>{number}번</p>
                                <p className="text-sm font-semibold leading-6 text-gray-900">{studentData.student.name}</p>
                                <div className="flex -space-x-1 overflow-hidden">
                                    {
                                        Object.entries(studentData.scenes).map((scene, idx) => (
                                            <div key={`${scene[1].id}${number}`}>
                                                <div className="text-gray-900 text-xs">{scene[0]}</div>
                                                {scene[1].images && scene[1].images.map((img, idx) => (
                                                    <img
                                                        key={img.id}
                                                        className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                                        src={img.thumbnail}
                                                        alt=""
                                                    />
                                                ))}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                studentData.select_finished ?
                                    <TextButton label="다운로드"
                                        handleClick={() => handleDownload(studentData.student.id)}
                                        enabled={!loadingState[studentData.student.id]}
                                        loading={loadingState[studentData.student.id]} />
                                    : <TextButton label="사진선택"
                                        handleClick={() => {
                                            setSelectedStudent(studentData.student)
                                            setModalOn(true)
                                        }}
                                    />
                            }
                        </div>
                        {
                            (studentData.request_message) ?
                                <div className="border-2 border-tahiti rounded-lg justify-between flex mt-2">
                                    <span className="inline-block align-bottom pl-3 py-2">{studentData.request_message}</span>
                                </div> : null
                        }
                    </li>

                )
            })
        })
        return listItems;
    }

    return (
        <div className="border p-4 rounded-lg shadow">
            <ul role="list" className="divide-y divide-gray-100">
                {setListItems()}
            </ul>
            <SelectPhotoModal studentId={selectedStudent ? selectedStudent.id : 0} />
        </div>
    )
}

export default StudentPhotoList;