import { useParams } from "react-router-dom";
import { getClassNames } from "../../services/apis/SchoolApi";
import { useState, useEffect } from "react";
import { getStudentListForStudio, addStudent } from "../../services/apis/SchoolApi";
import { StudentListForShot } from "../../components/List/StudentList"
import { useRecoilValue, useRecoilState } from "recoil";
import { currentClassStudentsState, sceneState } from "../../stores/studioAtoms";
import {
    currentStudentSelector
} from "../../stores/studioSelectors";
import Modal from "../../components/Modal/Modal"
import Headline from "../../components/Headline/Headline";
import ClassroomSelector from "../../components/Selector/ClassroomSelector";
import { classroomState } from "../../stores/schoolAtoms";
import Button from "../../components/Button/Button";



const TakingPhotoPage = () => {
    const { schoolId } = useParams();
    const [classrooms, setClassrooms] = useState([]);
    const [classroom, setClassroom] = useRecoilState(classroomState)
    const [students, setStudents] = useRecoilState(currentClassStudentsState)
    const currentStudent = useRecoilValue(currentStudentSelector)
    const [scene, setScene] = useRecoilState(sceneState)
    const [addUI, setAddUI] = useState(false);
    const [newStudent, setNewStudent] = useState({
        "number": "",
        "name": "",
        "email": "",
        "record": null,
    })
    const [errorMessage, setErrorMessage] = useState("")

    const handleChange = (classroom) => {
        getStudentListForStudio(schoolId, classroom.id).then((res) => {
            setClassroom(classroom)
            setStudents(res.data)
        })
    }


    const handleAddStudentClick = () => {
        addStudent(schoolId, classroom.id, newStudent).then(res => {
            if (res.status === 201) {
                setNewStudent({
                    "number": "",
                    "name": "",
                    "email": "",
                    "record": null,
                })
                setErrorMessage("")
                setAddUI(false)
                setStudents([...students, res.data])
            } else {
                setNewStudent({ ...newStudent, number: "" })
                setErrorMessage(res.data.message)
            }
        })
    }

    const handleNewStudentChange = (event) => {
        setNewStudent({ ...newStudent, [event.target.name]: event.target.value });
    }

    const handleAddingStudentUI = () => {
        if (!classroom) return null
        if (addUI) {
            return (
                <div className="mt-3">
                    <div className="flex flex-col sm:flex-row gap-3">
                        <input className="border w-full sm:w-8" placeholder="번호" name="number" onChange={handleNewStudentChange} value={newStudent.number} />
                        <input className="border w-full sm:w-16" placeholder="이름" name="name" onChange={handleNewStudentChange} value={newStudent.name} />
                        <input className="border w-full sm:w-40" placeholder="이메일" name="email" onChange={handleNewStudentChange} value={newStudent.email} />
                        <span className="text-blue-400 cursor-pointer hover:bg-blue-200 hover:text-white px-2" onClick={handleAddStudentClick}>추가</span>
                    </div>
                    <p className="text-red-400 mx-auto italic">{errorMessage}</p>
                </div>
            )
        }
        else {
            return (
                <div className="w-36 mx-auto mt-2">
                    <Button onClick={() => { setAddUI(true) }}>+ 학생 추가</Button>
                </div>
            )
        }
    }


    useEffect(() => {
        setStudents([])
        if (!scene) {
            setScene(JSON.parse(localStorage.getItem("currentScene")))
        }
        getClassNames(schoolId).then((res) => {
            setClassrooms(res.data);
        })
            .catch((err) => {
                console.log(err);
            })
        if (classroom) {
            handleChange(classroom)
        }
    }, [])

    return (
        <>
            <Headline label={`${scene ? scene.name : ""} 촬영`} />
            <br />
            <ClassroomSelector classrooms={classrooms} handleChange={handleChange} />
            {
                (students.length > 0) ?
                    <>
                        <div className="flex justify-center">
                            <Modal
                                currentStudent={currentStudent}
                            />

                        </div>
                        <StudentListForShot students={students} scene={scene} />
                    </>
                    : null
            }
            {handleAddingStudentUI()}
        </>
    )
}

export default TakingPhotoPage;