import { useState, useEffect } from 'react'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { sceneState } from '../../stores/studioAtoms'
import { useNavigate, useParams } from 'react-router-dom'
import { getScenes } from '../../services/apis/StudioApi'
import Selector from './Selector'


export const SceneList = ({ isUsedOn, fit = false }) => {
    const [selected, setSelected] = useState({ name: "촬영 선택" })
    const [scene, setSceneState] = useRecoilState(sceneState)
    const navigate = useNavigate()
    const { schoolId } = useParams()
    const [scenes, setScenes] = useState([])
    const setScene = (selected) => {
        setSelected(selected)
        setSceneState(selected)
        localStorage.setItem("currentScene", JSON.stringify(selected))
        if (isUsedOn === "shot") navigate(`/studio/school/${schoolId}/photo`)
    }
    const sceneLabel = (scene) => {
        return scene.name
    }

    useEffect(() => {
        getScenes(schoolId).then(res => {
            setScenes(res.data)
        })
    }, [])

    if (scenes.length === 0) {
        return <div>먼저, 촬영을 추가해주세요</div>
    }
    return (
        <Selector
            label={scene ? scene.name : "촬영 선택"}
            items={scenes}
            handleChange={setScene}
            itemLabel={sceneLabel}
            fit={fit}
        />
    )
}

export default SceneList