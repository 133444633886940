import React, { useEffect, useState } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../../components/Tooltip/Tooptip";
import { getTimeOnly } from "../../../../utils/datetime_formats";
import {
    selectedPhotoIndexState,
    modalOnState,
    selectedPhotoState,
    previewStudentIdState,
    selectedStudentState,
    studentPhotosState,
    allStudentPhotosState,
} from "../../../../stores/studioAtoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useSnackbar } from "../../../../components/Layout/SnackbarProvider";


export default function StudentPhotoItem({ classroom, number, studentData }) {
    const student = studentData.student
    const imageList = []
    const [currentStudentPhotos, setCurrentStudentPhotos] = useState([])
    const [studentPhotos, setStudentPhotos] = useRecoilState(studentPhotosState)
    const setPreviewStudentId = useSetRecoilState(previewStudentIdState)
    const setSelectedStudent = useSetRecoilState(selectedStudentState)
    const setStudentPhotoIdx = useSetRecoilState(selectedPhotoIndexState)
    const setModalOn = useSetRecoilState(modalOnState)
    const setSelectedPhoto = useSetRecoilState(selectedPhotoState);
    const [allStudentPhotos, setAllStudentPhotos] = useRecoilState(allStudentPhotosState)
    const { showSnackbar } = useSnackbar();

    const handleClick = (img) => {
        setPreviewStudentId(student.id)
        if (!allStudentPhotos[student.id]) {
            console.log(allStudentPhotos)
            showSnackbar("이미지 로딩중\n잠시후 다시 시도해주세요")
            return
        }
        setStudentPhotos(allStudentPhotos[student.id])
        const idx = allStudentPhotos[student.id].findIndex(photo => photo.id === img.id)
        setStudentPhotoIdx(idx)
        setSelectedStudent(student)
        setModalOn(true)
        setSelectedPhoto(img)
    }

    useEffect(() => {
        const updatedPhotos = []
        Object.entries(studentData.scenes).forEach(([scene, sceneData]) => {
            updatedPhotos.push(...sceneData.images)
        })
        const uniquePhotos = Array.from(new Set(updatedPhotos));
        // console.log(uniquePhotos)
        setCurrentStudentPhotos(uniquePhotos);
    }, [studentData])

    useEffect(() => {
        if (currentStudentPhotos.length > 0) {
            setAllStudentPhotos(prevAllStudentPhotos => ({
                ...prevAllStudentPhotos,
                [student.id]: currentStudentPhotos
            }));
        }
    }, [currentStudentPhotos])

    return (
        <li
            key={student.id}
            className="flex justify-between gap-x-6 py-5 hover:bg-gray-100 px-2"
            onClick={() => null}
        >
            <div className="flex min-w-0 gap-x-4">
                <div className="content-center">
                    <div className="inline">{classroom}반</div>
                    <div className="ml-2 inline">{number ?? 0}번</div>
                    <div className="ml-2 inline text-sm font-semibold leading-6 text-gray-900">{student.name}</div>
                </div>
                <div className="flex -space-x-1 overflow-hidden">
                    {
                        Object.entries(studentData.scenes).forEach(([scene, sceneData]) => {
                            imageList.push(<div key={`${student.id}/${scene}`}>
                                <div className="text-gray-900 text-xs">{scene}</div>
                                {sceneData.images.map((img, idx) => (
                                    <Tooltip key={`${student.id}-${idx}`}>
                                        <TooltipTrigger>
                                            <img
                                                key={`${student.id}-${idx}`}
                                                className="inline-block h-6 w-6 rounded-full ring-2 ring-white hover:cursor-pointer hover:h-7 hover:w-7 hover:ring-gray"
                                                src={img.thumbnail}
                                                alt=""
                                                onClick={() => {
                                                    handleClick(img)
                                                }}
                                            />
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <div className="bg-gray-100 px-2 rounded">
                                                촬영시각: {getTimeOnly(img.taken_at)}
                                            </div>
                                        </TooltipContent>
                                    </Tooltip>
                                ))}
                            </div>)
                        })
                    }
                    {imageList}
                </div>
            </div>
        </li>
    )
}