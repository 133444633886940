export default function UnmatchedPhotos({ unmatchedPhotos }) {
    const buildUnmatchedPhotos = () => {
        var imageList = []

        return <div className="flex -space-x-1 overflow-hidden">
            {
                unmatchedPhotos.map((photo) => {
                    imageList.push(
                        <img
                            key={photo.id}
                            className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                            src={photo.thumbnail}
                            alt=""
                        />
                    )
                })
            }
            {imageList}
        </div>
    }

    return (
        <>
            <p>미분류 사진</p>
            {buildUnmatchedPhotos()}
        </>
    )
}