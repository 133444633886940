import { atom } from "recoil";


export const schoolNameState = atom({
    key: "schoolNameState",
    default: ""
})

export const classroomState = atom({
    key: "classroomState",
    default: ""
})
