import Uploader from "./components/Uploader";
import StudentPhotoList from "./components/StudentPhotoList";
import Button from "../../../components/Button/Button";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { sceneState } from "../../../stores/studioAtoms";
import { getPhotoSelectSchedule, startSelectPhoto, endSelectPhoto } from "../../../services/apis/StudioApi";


const UploadPhotoPage = () => {
    const [selectStarted, setSelectStarted] = useState(false)
    const { schoolId } = useParams();
    const setSceneState = useSetRecoilState(sceneState);

    const handleClick = () => {
        if (selectStarted) {
            endSelectPhoto(schoolId).then(res => {
                if (res.status === 200) {
                    setSelectStarted(false)
                }
            })
        } else {
            startSelectPhoto(schoolId).then(res => {
                if (res.status === 200) {
                    setSelectStarted(true)
                }
            })
        }
    }

    useEffect(() => {
        setSceneState(null)
        getPhotoSelectSchedule(schoolId).then(res => {
            if (res.status === 200) {
                // console.log(`start_at: ${res.data[0].start_at}`)
                // console.log(`end_at: ${res.data[0].end_at}`)
                var startAt = res.data[0].start_at
                var endAt = res.data[0].end_at
                if (endAt) {
                    if (endAt > startAt) {
                        console.log("end_at > start_at")
                        setSelectStarted(false)
                    } else {
                        setSelectStarted(true)
                    }
                } else if (startAt) {
                    setSelectStarted(true)
                } else {
                    setSelectStarted(false)
                }
            }
        }
            , [])
    }, [])



    return (
        <>
            <div className="flex p-2 w-screen">
                <Button
                    color={selectStarted ? "midnight" : "blue"}
                    onClick={handleClick}>{selectStarted ? "사진 선택 마감" : "사진 선택 시작"}</Button>
            </div>
            <div className="flex flex-col md:flex-row w-screen">
                <div className="md:w-1/3 p-2">
                    <Uploader />
                </div>
                <div className="md:w-2/3 p-2">
                    <StudentPhotoList />
                </div>

            </div>
        </>
    )
}

export default UploadPhotoPage;