import BigButton from "../../components/Button/BigButton";
import { useNavigate } from "react-router-dom";


const SchoolDetailPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="mb-8">
                <BigButton handleClick={() => navigate('scene')} label="사진 촬영" />
            </div>
            <BigButton handleClick={() => navigate('upload')} label="사진 업로드" />
            <div className="mt-8">
                <BigButton handleClick={() => navigate('selected-photos')} label="사진 확인" />
            </div>

        </>
    )
}

export default SchoolDetailPage;