import { useRecoilState, useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { currentStudentSelector, shotSelector } from "../../stores/studioSelectors";
import { currentStudentState } from "../../stores/studioAtoms";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useRecoilValue } from 'recoil';
import { endPhoto } from "../../services/apis/StudioApi";


const CurrentStudentPage = () => {
    const [currentStudent] = useRecoilState(currentStudentSelector);
    const setCurrentStudent = useSetRecoilState(currentStudentState);
    const { schoolId } = useParams();
    const navigate = useNavigate();
    const shot = useRecoilValue(shotSelector);
    const endShot = async () => {
        await endPhoto(shot.id);
        navigate(`/studio/school/${schoolId}/photo`);
    }
    const startTime = shot ? new Date(shot.start_time) : new Date()
    const hour = shot ? startTime.getHours() : ""
    const minutes = shot ? startTime.getMinutes() : ""

    var number = (currentStudent && currentStudent.number === 99) ? "교사" : `${currentStudent.number ?? 0}번`

    return (
        <>
            <img
                className="mx-auto h-24 w-auto"
                src="/images/icon_camera.png"
                alt="camera"
            />
            <div className="text-lg text-red-400 w-full px-4 text-center">
                <div className="border-red-400 border-2">촬영중</div>
            </div>
            <p className="text-4xl my-3 font-bold">{currentStudent ? `${number} ${currentStudent.name}` : ""}</p>
            <p className="mb-4">시작: {`${hour}시 ${minutes}분`}</p>
            <Button onClick={endShot} color="midnight">촬영종료</Button>
        </>
    )
}

export default CurrentStudentPage;