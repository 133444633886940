import Selector from './Selector'


const SchoolSelector = ({ schools, onSchoolSelect }) => {
    const itemLabel = (school) => {
        return (
            <>
                {school.name} {school.grade}학년<span className='text-sm text-grey-500'>({school.region})</span>
            </>
        )
    }

    return (
        <Selector
            label="학교"
            items={schools}
            handleChange={onSchoolSelect}
            itemLabel={itemLabel} />
    )
}

export default SchoolSelector
