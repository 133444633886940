import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { modalOnState, selectedStudentState } from '../../stores/studioAtoms';
import { useRecoilState } from 'recoil';
import TextButton from '../Button/TextButton';
import { useState } from 'react';
import { selectStudentPhoto, getStudentUnselectedPhotos } from '../../services/apis/StudioApi';


const SelectPhotoModal = ({ studentId }) => {
    const [modalOn, setModalOn] = useRecoilState(modalOnState)
    const [selectedImg, setSelectedImg] = useState(null);
    const [photos, setPhotos] = useState([]);
    const [selectN, setSelectN] = useState(1);
    const [sceneName, setSceneName] = useState("")
    const [selectedN, setSelectedN] = useState(1)
    const [selectedStudent, setSelectedStudent] = useRecoilState(selectedStudentState);

    function closeModal() {
        setModalOn(false)
    }

    const handleSelect = () => {
        var data = {
            "photoId": selectedImg.id,
        }
        // console.log(data)
        selectStudentPhoto(data).then(res => {
            if (res.status === 200) {
                getUnselectedPhotos(studentId)
            }
        })
    }

    const getUnselectedPhotos = () => {
        getStudentUnselectedPhotos(selectedStudent.id).then(res => {
            if (res.status === 200) {
                // console.log(res.data)
                setSelectedImg(null)
                setSelectN(res.data.select_n)
                setPhotos(res.data.photos ?? [])
                setSelectedImg(res.data.photos[0])
                setSceneName(res.data.scene_name)
                setSelectedN(res.data.selected_n)
            } else if (res.status === 204) {
                closeModal()
            }
        })
    }


    useEffect(() => {
        if (selectedImg) {
            // photos.push(selectedImg)
        }
    }
        , [selectedImg]
    )

    useEffect(() => {
        if (!selectedStudent) return;
        getUnselectedPhotos(selectedStudent.id)
    }, [modalOn])

    return (
        <Transition appear show={modalOn} as={Fragment}>
            <Dialog as="div" className="relative z-10 justify-center" onClose={closeModal}>
                <Dialog.Overlay className="fixed inset-0 bg-gray opacity-50" />
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >

                            <div className="fixed inset-0 flex items-center justify-center p-4">
                                <Dialog.Panel
                                    className="bg-white rounded max-w-md mx-auto transform overflow-hidden text-center align-middle shadow-xl transition-all border-2 border-black">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-blue-900 mt-3"
                                    >
                                        {`${sceneName} (${selectN}장 중 ${selectedN + 1}장 선택중)`}
                                    </Dialog.Title>
                                    <div className='flex flex-row gap-x-1 items-center overflow-x-auto'>
                                        {
                                            photos && photos.map((photo) => {
                                                var classNames = 'w-12 cursor-pointer'
                                                if (selectedImg && photo.id === selectedImg.id) {
                                                    classNames += ' border-4 border-tahiti'
                                                }
                                                return (
                                                    <div key={photo.id}>
                                                        <div className={classNames} onClick={() => { setSelectedImg(photo) }}>
                                                            <img src={photo.thumbnail} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className='flex-grow'></div>
                                        <div className='mr-3'>
                                            <TextButton label="선택" handleClick={handleSelect} />
                                        </div>

                                    </div>
                                    <div className='border border-blue'></div>
                                    {
                                        photos.length === 0
                                            ? <div className='px-4 pt-4'>
                                                먼저, 사진을 업로드해주세요
                                            </div> : null
                                    }
                                    <div className='flex p-4'>
                                        {
                                            selectedImg ?
                                                <img src={selectedImg.original} alt="원본 사진" />
                                                : null
                                        }

                                    </div>
                                    <div className="mt-4 flex justify-center">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center border border-gray bg-white border-gray-400 px-9 py-1 mb-3 text-sm text-gray-500 font-medium text-blue-900 hover:bg-midnight-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            닫기
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default SelectPhotoModal;