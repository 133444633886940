import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { studioState } from "../../stores/studioAtoms";
import { useRecoilState } from "recoil";
import { login as loginAPI, freelancerLogin } from "../../services/apis/StudioApi";

const Login = (props) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [studioNameError, setstudioNameError] = useState("")
    const [studio, setStudio] = useRecoilState(studioState)
    const [isFreelancer, setIsFreelancer] = useState(false)
    const [studioName, setStudioName] = useState("")

    const navigate = useNavigate();

    const logIn = () => {
        if (isFreelancer) {
            var data = {
                "studio_name": studioName,
                "password": password,
            }
            freelancerLogin(data).then(r => {
                if (r.status == 200) {
                    return r.data
                } else if (r.status == 428) {
                    setPasswordError(r.data)
                } else {
                    setPasswordError("사진관 이름 혹은 패스워드가 잘못되었습니다.")
                    return false
                }
            }).then(r => {
                if (!r) return;
                let email = "free@lancer.pnp"
                localStorage.setItem("user", JSON.stringify({ email, access: r.access }))
                props.setLoggedIn(true)
                props.setEmail(email)
                localStorage.setItem("studioName", JSON.stringify(r.studio_name))
                localStorage.setItem("studioId", JSON.stringify(r.studio_id))
                setStudio(r.studio)
                navigate("/studio/school/")
            })
        } else {
            loginAPI(email, password)
                .then(r => {
                    if (r.status == 200) {
                        return r.data
                    } else {
                        setPasswordError("이메일 혹은 패스워드가 잘못되었습니다")
                    }
                })
                .then(r => {
                    localStorage.setItem("user", JSON.stringify({ email, access: r.access }))
                    props.setLoggedIn(true)
                    props.setEmail(email)
                    localStorage.setItem("studioName", JSON.stringify(r.studio_name))
                    localStorage.setItem("studioId", JSON.stringify(r.studio_id))
                    setStudio(r.studio)
                    navigate("/studio/school/")
                })
                .catch(e => {
                    console.error(e)
                })
        }
    }

    const onButtonClick = () => {
        // Set initial error values to empty
        setEmailError("")
        setPasswordError("")
        setstudioNameError("")

        // Check if the user has entered both fields correctly
        if (isFreelancer) {
            if ("" === studioName) {
                setstudioNameError("스튜디오 이름을 입력하세요")
                return
            }
        } else {
            if ("" === email) {
                setEmailError("이메일을 입력하세요")
                return
            }

            if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
                setEmailError("이메일 형식이 올바르지 않습니다")
                return
            }

        }


        if ("" === password) {
            setPasswordError("Please enter a password")
            return
        }

        if (password.length < 7) {
            setPasswordError("The password must be 8 characters or longer")
            return
        }
        logIn()

        // // Check if email has an account associated with it
        // checkAccountExists(accountExists => {
        //     // If yes, log in 
        //     if (accountExists)
        //         logIn()
        //     else
        //         // Else, ask user if they want to create a new account and if yes, then log in
        //         if (window.confirm("An account does not exist with this email address: " + email + ". Do you want to create a new account?")) {
        //             logIn()
        //         }
        // })

        const checkAccountExists = (callback) => {
            fetch("http://localhost:3080/check-account", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            })
                .then(r => r.json())
                .then(r => {
                    callback(r?.userExists)
                })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            onButtonClick()
        }
    }

    useEffect(() => {
        if (studio) {
            navigate("/studio/school/")
        }
    }, [])


    return <div className={"mainContainer"}>
        <div className="text-3xl font-bold">
            <div>사진관 로그인</div>
        </div>
        <br />
        <div className="flex justify-center w-72 md:w-96 flex-col">
            {isFreelancer ?
                <>
                    <input
                        value={studioName}
                        placeholder="스튜디오이름을 입력하세요"
                        onChange={ev => setStudioName(ev.target.value)}
                        className="h-12 w-72 md:w-96 border border-gray pl-2 rounded-md" />
                    <label className="errorLabel">{studioNameError}</label>
                </> :
                <>
                    <input
                        value={email}
                        placeholder="이메일을 입력하세요"
                        onChange={ev => setEmail(ev.target.value)}
                        className="h-12 w-72 md:w-96 border border-gray pl-2 rounded-md" />
                    <label className="errorLabel">{emailError}</label>
                </>
            }
        </div>
        <br />
        <div className="flex justify-center w-72 md:w-96 flex-col">
            <input
                value={password}
                placeholder="비밀번호를 입력하세요"
                onChange={ev => setPassword(ev.target.value)}
                onKeyDown={handleKeyDown}
                className="h-12 w-72 md:w-96 border border-gray pl-2 rounded-md"
                type="password" />
            <label className="errorLabel">{passwordError}</label>
        </div>
        <label>
            <input
                type="checkbox"
                checked={isFreelancer}
                onChange={() => { setIsFreelancer(!isFreelancer) }}
            />
            프리랜서
        </label>
        <br />
        <button
            type="button"
            onClick={onButtonClick}
            className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            로그인
        </button>
    </div>
}

export default Login;