import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { currentStudentState, modalOnState } from "../../stores/studioAtoms";
import { updateStudent } from "../../services/apis/SchoolApi";
import { useParams } from "react-router-dom";


const StudentList = ({ students }) => {
    const [editStudentId, setEditStudentId] = useState(null);
    const [editedStudent, setEditedStudent] = useState({ name: '', email: '' });
    // const [editMode, setEditMode] = useState({});
    const [editIdx, setEditIdx] = useState({})
    const { schoolId, classId } = useParams()

    const handleEditClick = (student, studentIdx) => {
        // setEditMode({ ...editMode, [student.id]: !editMode[student.id] });
        setEditStudentId(student.id);
        setEditedStudent({ name: student.name, email: student.email, id: student.id });
        setEditIdx({ ...editIdx, [student.id]: studentIdx })
    };

    let liClass = "flex justify-between gap-x-6 py-5 px-3"

    const handleSave = (id) => {
        updateStudent(schoolId, classId, editedStudent).then(
            (res) => {
                if (res.status === 200) {
                    // setEditMode({ ...editMode, [id]: false });
                    setEditStudentId(null);
                    students[editIdx[id]].name = editedStudent.name
                    students[editIdx[id]].email = editedStudent.email
                    setEditedStudent({ name: '', email: '' });
                } else {
                    console.log('Error:', res.data);
                }
            }
        ).catch(
            (err) => {
                console.log('Error:', err);
            }
        )

        setEditStudentId(null);
    };

    const handleCancel = (student) => {
        // setEditMode({ ...editMode, [student.id]: false });
        setEditStudentId(null);
    };

    const handleChange = (e, field) => {
        setEditedStudent({ ...editedStudent, [field]: e.target.value });
    };

    const setEditRow = (student) => {
        return (
            <div className="flex flex-auto justify-between">
                <input
                    type="text"
                    value={editedStudent.name}
                    onChange={(e) => handleChange(e, 'name')}
                    className="flex-1 text-sm font-semibold leading-6 text-gray-900 border-gray-400 border w-20 h-6"
                />
                <input
                    type="email"
                    value={editedStudent.email}
                    onChange={(e) => handleChange(e, 'email')}
                    className="flex-1 text-sm leading-6 ml-3 border-gray-400 border w-48 h-6"
                />
                <p onClick={() => handleSave(student.id)}
                    className="flex-1 text-xs leading-5 ml-3 text-blue-300 cursor-pointer inline-block">저장</p>
                <p
                    onClick={() => handleCancel(student)}
                    className="flex-1 text-xs leading-5 ml-3 text-gray-900 cursor-pointer inline-block"
                >취소</p>
            </div>
        )
    }

    const setReadRow = (student, studentIdx) => {
        return (
            <p className="text-sm font-semibold leading-6 text-gray-900 flex justify-between">
                {student.name}
                <span className="ml-3 mt-1 truncate text-xs leading-5 text-gray-500">{student.email}</span>
                <span
                    className="ml-3 mt-1 truncate text-xs leading-5 text-red-300 cursor-pointer"
                    onClick={() => handleEditClick(student, studentIdx)}
                >
                    편집
                </span>
            </p>
        )
    }

    return (
        <ul role="list" className="divide-y divide-gray-100">
            {students && students.map((student, studentIdx) => (
                <li key={student.id} className={liClass}>
                    <div className="flex min-w-0 gap-x-4">
                        <p>{student.number}</p>
                        <div className="w-80 flex-auto">
                            {/* {editMode[student.id] ? setEditRow(student) : setReadRow(student, studentIdx)} */}
                            {(editStudentId === student.id) ? setEditRow(student) : setReadRow(student, studentIdx)}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    )
}

const StudentListForShot = ({ students, scene }) => {
    const setCurrentStudent = useSetRecoilState(currentStudentState);
    const setModalOn = useSetRecoilState(modalOnState);

    // 촬영마다 촬영여부를 비교
    // console.log(students)


    let liClass = "flex w-full justify-between gap-x-6 py-5 px-3 cursor-pointer hover:bg-gray-100"
    return (
        <div>
            <ul role="list" className="divide-y divide-gray-100">
                <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-400"></div>
                    <span className="flex-shrink mx-4 text-gray-400">촬영 전</span>
                    <div className="flex-grow border-t border-gray-400"></div>
                </div>
                {students && students.map((student) => {
                    console.log(`student records length:: ${student.records.length}`)
                    var taken = false;
                    student.records.forEach(record => {
                        if (record.scene == scene.id) {
                            taken = true;
                        }
                    })
                    if (!taken) {
                        return <li key={student.id} className={liClass} onClick={() => {
                            setCurrentStudent(student) // How to extract it outside as a method
                            setModalOn(true)
                        }}>
                            <div className="flex gap-x-4">
                                <p>{(student.number === 99) ? "교사" : student.number}</p>
                                <div className="min-w-0 flex-auto">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">{student.name}
                                        <span className="ml-3 mt-1 truncate text-xs leading-5 text-gray-500">{student.email}</span></p>
                                </div>
                            </div>
                        </li>
                    }

                })}
                <div className="relative flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-400"></div>
                    <span className="flex-shrink mx-4 text-gray-400">촬영 완료</span>
                    <div className="flex-grow border-t border-gray-400"></div>
                </div>
                {students && students.map((student) => {
                    if (student.records.length === 0) return null;
                    var result;
                    student.records.forEach(record => {
                        // console.log(`record:: ${record}`)
                        // console.log(`record.scene:: ${record.scene}`)
                        if (record.scene != scene.id) return
                        var recordDt = new Date(record.end_time)
                        var recordH = recordDt.getHours()
                        var recordM = recordDt.getMinutes()
                        var recordS = recordDt.getSeconds()
                        result = (
                            <li key={student.id} className={liClass} onClick={() => {
                                setCurrentStudent(student) // How to extract it outside as a method
                                setModalOn(true)
                            }}>
                                <div className="flex w-full gap-x-4">
                                    <p>{(student.number === 99) ? "교사" : student.number}</p>
                                    <div className="min-w-0">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">{student.name}
                                            <span className="ml-3 mt-1 truncate text-xs leading-5 text-gray-500">{student.email}</span></p>
                                    </div>
                                    <p className="grow text-end text-midnight-400">{`${recordH}시${recordM}분${recordS}초`}</p>
                                </div>
                            </li>
                        )
                    });
                    return result
                    // for (var record in student.records) {
                    // console.log(`record:: ${record}`)
                    //     if (record.scene != scene.id) continue
                    //     var recordDt = new Date(record)
                    //     var recordH = recordDt.getHours()
                    //     return (
                    //         <li key={student.id} className={liClass} onClick={() => {
                    //             setCurrentStudent(student) // How to extract it outside as a method
                    //             setModalOn(true)
                    //         }}>
                    //             <div className="flex w-full gap-x-4">
                    //                 <p>{(student.number === 99) ? "교사" : student.number}</p>
                    //                 <div className="min-w-0">
                    //                     <p className="text-sm font-semibold leading-6 text-gray-900">{student.name}
                    //                         <span className="ml-3 mt-1 truncate text-xs leading-5 text-gray-500">{student.email}</span></p>
                    //                 </div>
                    //                 <p className="grow text-end text-midnight-400">{`${recordH}시${recordDt.getMinutes()}분`}</p>
                    //             </div>
                    //         </li>
                    //     )
                    // }
                })}
            </ul>

        </div>
    )
}

export default StudentList;
export { StudentListForShot };