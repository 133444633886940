import SceneList from "../../../../components/Selector/SceneSelector";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { imagesSelector, sceneSelector } from "../../../../stores/studioSelectors";
import ImageList from "../../../../components/List/ImageList";
import { PhotoIcon } from "@heroicons/react/20/solid";
import { imagesState } from "../../../../stores/studioAtoms";
import Button from "../../../../components/Button/Button";
import { uploadPhotos as uploadPhotosApi } from "../../../../services/apis/StudioApi";
import { useState, useEffect } from "react";
import { useSnackbar } from "../../../../components/Layout/SnackbarProvider";
import { useParams } from "react-router-dom";
import { wsHost } from "../../../../services/apis/common";


const Uploader = () => {
    const images = useRecoilValue(imagesSelector)
    const scene = useRecoilValue(sceneSelector)
    const setImagesState = useSetRecoilState(imagesState);
    const [imageFiles, setImageFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0)
    const [uploadCountWord, setUploadCountWord] = useState("")
    const [uploadError, setUploadError] = useState("")
    const { showSnackbar } = useSnackbar();
    const [socket, setSocket] = useState(null)
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("")
    const { schoolId } = useParams();

    useEffect(() => {
        const SOCKET_SERVER_URL = `${wsHost}ws/upload/${schoolId}/`
        const newSocket = new WebSocket(SOCKET_SERVER_URL);

        newSocket.onopen = () => {
            console.log("WebSocket Connected");
        };

        newSocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log(message);
            setMessage(message.message)
            setMessages((prevMessages) => [...prevMessages, message]);
            // setImagesState([]) // 매번 GET 호출을 하지 않도록
        };

        setSocket(newSocket);

        return () => {
            if (newSocket.readyState === WebSocket.OPEN) {
                newSocket.close();
            }
        }
    }, [setSocket])

    const setImages = (e) => {
        console.log(e.target.files)
        setImageFiles(e.target.files);
        const uploadedFiles = e.target.files;
        const filesInfo = Array.from(uploadedFiles).map(file => ({
            name: file.name,
            size: file.size,
            type: file.type
        }));
        setImagesState([...images, ...filesInfo]);
    }

    const uploadPhotos = async () => {
        if (!scene) {
            showSnackbar("촬영을 선택해주세요")
            return
        }
        if (imageFiles.length === 0) {
            showSnackbar("업로드할 사진을 선택해주세요")
            return
        }
        setMessage("")
        setUploadProgress(0)
        setIsUploading(true)
        setUploadError("")


        const chunkSize = 20;
        for (let i = 0; i < imageFiles.length; i += chunkSize) {
            const chunk = Array.from(imageFiles).slice(i, i + chunkSize);
            let formData = new FormData();
            var studio_id = JSON.parse(localStorage.getItem("studioId"));
            formData.append("studio", studio_id);
            formData.append("scene", scene.id);
            formData.append("school", schoolId);

            chunk.forEach(file => {
                formData.append("photo", file);
            });

            try {
                const res = await uploadPhotosApi(formData, { setProgress: setProgress });
                console.log(res);
                // 각 청크 업로드가 완료된 후에 할 작업
                showSnackbar(`업로드 완료: ${i + chunk.length} / ${imageFiles.length}`);
                setUploadCountWord(`업로드 완료: ${i + chunk.length} / ${imageFiles.length}`)
                setUploadProgress(Math.round(((i + chunkSize) / imageFiles.length) * 100));
            } catch (err) {
                console.log(err);
                showSnackbar("업로드 중 오류 발생");

                // 남은 이미지 파일 기록
                setImagesState(images.slice(i));
                setImageFiles(imageFiles.slice(i));
                setUploadError("업로드 중 오류 발생. \n남은 파일 확인 후 재업로드를 진행하세요")

                setIsUploading(false);
                return;
            }

        }

        setIsUploading(false);
        document.getElementById('file-upload').value = '';
        setImageFiles([]);
        setImagesState([]);
        showSnackbar("모든 사진 업로드 완료. 서버에서 이미지 처리 시작");

    }

    return (
        <div className="border p-4 rounded-lg shadow">
            <h1 className="mb-2">사진 업로드</h1>
            <SceneList isUsedOn={"upload"} fit={true} />
            <div className="col-span-full">
                <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                    <div className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 ${isUploading ? "" : "hover:bg-gray-100"}`}>
                        <div className="text-center">
                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                            <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                                <span>{isUploading ? "업로드중" : "클릭해서 업로드"}</span>
                                <input
                                    disabled={isUploading}
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    multiple
                                    className="sr-only"
                                    onChange={setImages}
                                />
                            </div>
                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                        </div>
                    </div>
                </label>
            </div>
            <br />
            <Button onClick={uploadPhotos} disabled={isUploading}>업로드</Button>
            <div className={`${isUploading ? "" : "hidden"}`}>
                <p className="text-red">업로드 중에는 창을 닫거나 새로고침을 하면 안됩니다.</p><br />
                업로드 {uploadProgress}% 완료<br />
                {uploadCountWord}
            </div>
            <div className="text-red">{uploadError}</div>
            <div className={`${(isUploading && progress === 100) ? "" : "hidden"}`}>
                서버 응답 대기중
            </div>
            <div className="text-gray-500 mt-1">
                {message}
            </div>
            <ImageList />
        </div>
    )
}

export default Uploader;