import { redirect, RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import './App.css';
import { getMe } from './services/apis/StudioApi';
import UserSelectPage from './pages/userSelect/UserSelectPage';
import Login from './pages/studio/login';
import Layout from './components/Layout/Layout';
import SchoolRegistrationForm from './pages/studio/registerSchool/RegisterSchoolPage';
import TeacherLoginPage from './pages/school/login/LoginPage';
import StudentListPage from './pages/school/studentList/studentListPage';
import SelectSchoolPage from './pages/studio/selectSchool/SelectSchoolPage';
import SchoolDetailPage from './pages/studio/SchoolDetailPage';
import TakingPhotoPage from './pages/studio/TakingPhotoPage';
import CurrentStudentPage from './pages/studio/CurrentStudentPage';
import SelectScenePage from './pages/studio/SelectScenePage';
import CreateScenePage from './pages/studio/CreateScenePage';
import UploadPhotoPage from './pages/studio/uploadPhoto/UploadPhotoPage';
import SchoolAdminSelectPage from './pages/school/SelectSchoolPage';
import SelectedPhotosPage from './pages/studio/selectedPhotos/SelectedPhotosPage';
import StudioSchoolAdminPage from './pages/studio/schoolInfo/SchoolAdminPage';
import SelectMenuPage from './pages/school/SelectMenuPage';
import SchoolAdminPage from './pages/school/schoolAdmin/SchoolAdminPage';
import NotFoundPage from './pages/errorPage/NotFoundPage'
import TempPasswordPage from './pages/studio/tempPassword/tempPasswordPage';
import NewPasswordPage from './pages/studio/newPassword/NewPasswordPage';
import SchoolPostWritePage from './pages/school/board/SchoolPostWritePage';


function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState("")

  useEffect(() => {
    // Fetch the user email and token from local storage
    const user = JSON.parse(localStorage.getItem("user"))

    // If the token/email does not exist, mark the user as logged out
    if (!user || !user.token) {
      setLoggedIn(false)
      return
    }

    // If the token exists, verify it with the auth server to see if it is valid
    getMe()
      .then(r => {
        console.log(r.data)
        setLoggedIn(true)
        setEmail(r.data.email || "")
      })
  }, [])

  const authLoader = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (!user) {
      return redirect("/studio/login");
    }
    return null;
  };

  const router = createBrowserRouter([
    {
      path: "/", element: <Layout><Outlet /></Layout>, children: [
        { path: "/", element: <UserSelectPage /> },
        {
          path: "studio", children: [
            { path: "login", element: <Login setLoggedIn={setLoggedIn} setEmail={setEmail} /> },
            { path: "new-password", element: <NewPasswordPage /> },
            { path: "temp-password", element: <TempPasswordPage /> },
            {
              path: "school", children: [
                { path: "", element: <SelectSchoolPage />, loader: authLoader },
                { path: "register", element: <SchoolRegistrationForm /> },
                {
                  path: ":schoolId",
                  loader: authLoader,
                  children: [
                    { path: "", element: <SchoolDetailPage /> },
                    { path: "scene", element: <SelectScenePage /> },
                    { path: "scene/new", element: <CreateScenePage /> },
                    { path: "photo", element: <TakingPhotoPage /> },
                    { path: "photo/taking", element: <CurrentStudentPage /> },
                    { path: "upload", element: <UploadPhotoPage /> },
                    { path: "selected-photos", element: <SelectedPhotosPage /> },
                  ]
                },
              ]
            },
          ]
        },
        {
          path: "school", children: [
            { path: "", element: <SchoolAdminSelectPage />, loader: authLoader },
            {
              path: ":schoolId",
              children: [
                { path: "", element: <SelectMenuPage /> },
                { path: "info", element: <StudioSchoolAdminPage /> },
                { path: "login", element: <TeacherLoginPage /> },
                { path: "board/write", element: <SchoolPostWritePage /> },
                { path: "admin", element: <SchoolAdminPage />, loader: authLoader },
                {
                  path: "class/:classId",
                  loader: authLoader,
                  children: [
                    { path: "students", element: <StudentListPage /> },
                  ]
                },
              ]
            },
          ]
        },
        { path: "*", element: <NotFoundPage /> },
      ]
    },
  ])

  return (
    <RecoilRoot>
      <RouterProvider router={router} >
        <div className="App">
        </div>
      </RouterProvider>
    </RecoilRoot>
  );

}

export default App;
