import { selector } from "recoil";
import { schoolNameState, classroomState } from "./schoolAtoms";


export const schoolNameSelector = selector({
    key: 'schoolNameSelector',
    get: ({ get }) => {
        return get(schoolNameState)
    }
})

export const classroomSelector = selector({
    key: 'classroomSelector',
    get: ({ get }) => {
        return get(classroomState)
    }
})