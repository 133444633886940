import { useSnackbar } from "../../../components/Layout/SnackbarProvider";
import { useState } from "react";
import { requestChangePassword } from "../../../services/apis/StudioApi";
import { useNavigate } from "react-router-dom";

const NewPasswordPage = () => {
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState("")
    const { showSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const requestChange = () => {
        if (!currentPassword || !newPassword || !confirmPassword) {
            setError("모든 칸을 입력해주세요")
            return
        } else if (newPassword != confirmPassword) {
            setError("새 비밀번호와 비밀번호 확인이 일치하지 않습니다.")
            return
        }
        var data = {
            "password": currentPassword,
            "newPassword": newPassword,
        }
        requestChangePassword(data).then(res => {
            if (res.status == 200) {
                showSnackbar("변경완료")
                navigate("/")
            } else {
                setError(res.data.message)
            }
        })
    }

    return (
        <div className="flex flex-col justify-center">
            <h1 className="text-2xl font-semibold leading-7 text-gray-900 mb-4">비밀번호 변경</h1>
            <div className="flex justify-center flex-col mb-4">
                <input type="password"
                    className="w-72 md:w-96 h-12 border border-gray rounded-md pl-2"
                    value={currentPassword}
                    placeholder="현재 비밀번호"
                    onChange={ev => setCurrentPassword(ev.target.value)} />
                <input type="password"
                    className="w-72 md:w-96 h-12 border border-gray rounded-md pl-2 mt-4"
                    value={newPassword}
                    placeholder="새 비밀번호"
                    onChange={ev => setNewPassword(ev.target.value)} />
                <p className="text-sm mt-2 text-midnight-400">
                    - 비밀번호는 최소 8자리 이상이어야 합니다.<br />
                    - 사용가능한 특수문자는 !@#$%^&* 입니다.
                </p>
                <input type="password"
                    className="w-72 md:w-96 h-12 border border-gray rounded-md pl-2 mt-4"
                    value={confirmPassword}
                    placeholder="새 비밀번호 확인"
                    onChange={ev => setConfirmPassword(ev.target.value)} />
                <label className="errorLabel">{error}</label>
            </div>
            <button
                type="button"
                className="rounded-md bg-midnight-600 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-midnight-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midnight-600"
                onClick={requestChange}
            >
                변경
            </button>
        </div>
    );
}

export default NewPasswordPage;