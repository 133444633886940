import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil"
import { classroomState, schoolNameState } from "../../stores/schoolAtoms";
import BigButton from "../../components/Button/BigButton";
import { fetchCurrentTime } from "../../services/apis/StudioApi"

const UserSelectPage = () => {
    const navigate = useNavigate();
    const setSchoolName = useSetRecoilState(schoolNameState)
    const setClassname = useSetRecoilState(classroomState)
    const [time, setTime] = useState('')

    const getCurrentTime = async () => {
        var res = await fetchCurrentTime()
        if (res.status === 200) {
            setTime(new Date(res.data.time).toLocaleTimeString())
        }

    }

    useEffect(() => {
        setSchoolName("")
        setClassname("")
        getCurrentTime()

        const interval = setInterval(() => {
            const newTime = new Date(new Date().getTime() + 1000);
            setTime(newTime.toLocaleTimeString());
        }, 1000);

        return () => clearInterval(interval);
    }, [])

    return (
        <div className="flex justify-center items-center flex-col">
            <div className="text-center mb-4">
                <p>현재 서버 시간</p>
                <p>{time}</p>
            </div>
            <div className="flex flex-col md:flex-row space-y-6 md:space-x-6 md:space-y-0 mb-10">
                <BigButton label="사진관" handleClick={() => navigate("/studio/school")} />
                <BigButton label="학교" handleClick={() => navigate("/school")} />
            </div>
        </div>
    )
}

export default UserSelectPage;