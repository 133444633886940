import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from "react-router-dom";
import { modalOnState, shotState } from '../../stores/studioAtoms';
import { modalOnSelector, sceneSelector } from '../../stores/studioSelectors';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { startPhoto as startPhotoApi } from '../../services/apis/StudioApi';


const StudentPhotoDialog = ({ currentStudent }) => {
    const navigate = useNavigate();
    const setModalOn = useSetRecoilState(modalOnState);
    const modalOn = useRecoilValue(modalOnSelector)
    const scene = useRecoilValue(sceneSelector)
    const setShot = useSetRecoilState(shotState)

    function closeModal() {
        setModalOn(false)
    }

    const startPhoto = async () => {
        setModalOn(false)
        var studio_id = JSON.parse(localStorage.getItem("studioId"))
        let shotData = {
            "student": currentStudent.id,
            "studio": studio_id,
            "scene": scene.id,
        }
        let res = await startPhotoApi(shotData)
        setShot(res.data)
        navigate('taking')
    }

    var number
    if (currentStudent) {
        if (currentStudent.number === 99) {
            number = "교사"
        } else {
            number = `${currentStudent.number ?? 0}번`
        }
    } else {
        number = "없음"
    }


    return (
        <Transition appear show={modalOn} as={Fragment}>
            <Dialog as="div" className="relative z-10 justify-center" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-80 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-blue-900"
                                >
                                    촬영 학생 선택
                                </Dialog.Title>
                                <div className="mt-2 flex justify-center">
                                    <p className="text-3xl text-gray-500">
                                        {number} {currentStudent ? currentStudent.name : "김철수"}
                                    </p>
                                </div>

                                <div className="mt-4 flex justify-center">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center border border-transparent bg-white border-gray-400 px-9 py-1 text-sm text-gray-500 font-medium text-blue-900 hover:bg-midnight-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={closeModal}
                                    >
                                        취소
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center border border-transparent bg-midnight-500 px-9 py-1 text-sm text-white font-medium text-blue-900 hover:bg-midnight-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 ml-4"
                                        onClick={startPhoto}
                                    >
                                        촬영
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default StudentPhotoDialog;
